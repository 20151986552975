/*--------------------------------------------------
List Test
--------------------------------------------------*/
#list-test {
    padding: 100px 0 0;

    @include xs {
        padding: 50px 0 0;
    }

    .wrap-list-test {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .item {
            width: calc(50% - 40px);
            margin: 40px 0;

            @include xs {
                width: 100%;
                margin: 20px 0;
                text-align: center;
            }

            img {
                width: 100%;
                max-width: 100%;
            }

            .tit-list-test {
                font-size: 42px;
                line-height: 52px;
                font-family: 'ElicaFavorit-Bold', sans-serif;
                color: #000;
                margin-top: 0;

                @include md {
                    font-size: 32px;
                    line-height: 42px;
                }

                @include sm {
                    font-size: 24px;
                    line-height: 32px;
                }

                @include sm {
                    margin-top: 20px;
                }

                &:after {
                    content: '';
                    display: block;
                    width: 70px;
                    height: 1px;
                    background-color: $orange;
                    margin-top: 30px;

                    @include xs {
                        position: relative;
                        margin: 0 auto;
                        margin-top: 30px;
                    }
                }
            }

            .txt-list-test {
                font-size: 20px;
                line-height: 32px;

                @include md {
                    font-size: 16px;
                    line-height: 28px;
                }

                @include sm {
                    font-size: 14px;
                    line-height: 26px;
                }
            }

            .read-more {
                font-size: 16px;
                font-family: 'ElicaFavorit-Bold', sans-serif;

                @include sm {
                    font-size: 14px;
                }
            }
        }
    }

    .sicurezza-img {
        order: 0;

        @include xs {
            order: 1;
        }
    }

    .sicurezza-txt {
        order: 1;

        @include xs {
            order: 0;
        }
    }

    .prestazioni-txt {
        order: 2;

        @include xs {
            order: 2;
        }
    }

    .prestazioni-img {
        order: 3;

        @include xs {
            order: 3;
        }
    }

    .rumore-img {
        order: 4;

        @include xs {
            order: 5;
        }
    }

    .rumore-txt {
        order: 5;

        @include xs {
            order: 4;
        }
    }

    .elettro-txt {
        order: 6;

        @include xs {
            order: 6;
        }
    }

    .elettro-img {
        order: 7;

        @include xs {
            order: 7;
        }
    }

    .pack-img {
        order: 8;

        @include xs {
            order: 9;
        }
    }

    .pack-txt {
        order: 9;

        @include xs {
            order: 8;
        }
    }
}