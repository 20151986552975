/*--------------------------------------------------
Titolo Sezioni
--------------------------------------------------*/
.tit-section {
    
    h1 {
        font-size: 78px;
        font-family: 'ElicaFavorit-Bold', sans-serif;
        line-height: 43px;
        color: #000;
        text-align: center;

        @include md {
            font-size: 68px;
        }

        @include xs {
            font-size: 42px;
            line-height: 42px;
        }

        &:after {
            content: '';
            display: block;
            position: relative;
            margin: 0 auto;
            width: 70px;
            height: 1px;
            background-color: $orange;
            margin-top: 55px;
        }
    }
}

.txt-section {
    position: relative;
    margin: 0 auto;
    text-align: center;
    width: 80%;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 30px;
}