/*--------------------------------------------------
Visual Interno
--------------------------------------------------*/
#visual-interno {
    padding: 50px 0;
    background: #000;
    background-size: cover!important;

    &.chi-siamo {
        background-image: url('../img/chi-siamo-visual.png');
        height: 80vh;
    }

    &.test {
        background-image: url('../img/test-visual.png');
        height: 80vh;
    }

    &.sicurezza {
        background-image: url('../img/sicurezza-visual.png');
        height: 80vh;

        @include xs {
            height: 60vh;
        }
    }

    &.prestazione {
        background-image: url('../img/prestazione-visual.jpg');
        height: 80vh;

        @include xs {
            height: 60vh;
        }
    }

    &.rumore {
        background-image: url('../img/rumore-visual.jpg');
        height: 80vh;

        @include xs {
            height: 60vh;
        }
    }

    &.compatibilita {
        background-image: url('../img/compatibilita-visual.jpg');
        height: 80vh;

        @include xs {
            height: 60vh;
        }
    }

    &.packaging {
        background-image: url('../img/packaging-visual.jpg');
        height: 80vh;

        @include xs {
            height: 60vh;
        }
    }

    &.ricerca {
        background-image: url('../img/ricerca-visual.jpg');
        height: 80vh;

        @include xs {
            height: 60vh;
        }
    }

    &.accreditamento {
        background-image: url('../img/accreditamento-visual.jpg');
        height: 80vh;

        @include xs {
            height: 60vh;
        }
    }

    .caption {
        width: 100%;
        text-align: center;
        padding: 130px 0;

        @include md {
            padding: 100px 0;
        }

        .tit-visual {
            font-size: 60px;
            font-family: 'ElicaFavorit-Bold', sans-serif;
            color: #fff;
            margin: 0;

            @include md {
                font-size: 42px;
            }

            @include xs {
                font-size: 32px;
            }
        }

        .abs-visual {
            font-size: 28px;
            font-family: 'ElicaFavorit-Bold', sans-serif;
            color: #fff;
            margin-bottom: 0;
            margin-top: 30px;

            @include md {
                font-size: 22px;
                margin-top: 10px;
            }

            @include xs {
                font-size: 18px;
            }
        }
    }

    .go-down {
        height: 50px;
        width: 50px;
        border-radius: 50px;
        border: 2px solid #fff;
        background: transparent;
        position: relative;
        margin: 0 auto;
        display: block;
        color: #fff;
        font-size: 20px;
        line-height: 50px;
        text-align: center;
        opacity: .5;

        &:hover, &:focus {
            opacity: 1;
        }
    }
}