 /*--------------------------------------------------
Numeri Hp
--------------------------------------------------*/
#numeri-hp {
    padding: 50px 0 0;

    .tit-numeri-hp {
        font-size: 42px;
        font-family: 'ElicaFavorit-Bold', sans-serif;
        line-height: 50px;
        margin-top: 0;

        @include sm {
            text-align: center;
        }

        @include xs {
            font-size: 32px;
            line-height: 42px;
        }

        &:after {
            content: '';
            position: relative;
            display: block;
            width: 77px;
            height: 1px;
            margin-top: 30px;
            background-color: $orange;

            @include sm {
                margin: 0 auto;
                margin-top: 30px;
            }
        }
    }

    .txt-numeri-hp {
        position: relative;
        margin: 0 auto;
        text-align: center;
        width: 80%;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 30px;

        @include sm {
            font-size: 16px;
            line-height: 26px;
        }

        @include xs {
            width: 100%;
            font-size: 14px;
            line-height: 24px;
        }
    }
}


.wrap-dett-numeri {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 50px;

    .numero {
        width: calc(25% - 20px);
        padding: 40px 0px;

        @include md {
            width: calc(25% - 20px);
            text-align: center;
        }

        @include sm {
            width: calc(50% - 20px);
            margin-bottom: 50px;
        }

        @include xs {
            width: 100%;
        }

        span {
            font-size: 14px;
            color: #676767;
            font-family: 'ElicaFavorit-Bold', sans-serif;
        }

        h4 {
            font-size: 50px;
            font-family: 'ElicaFavorit-Bold', sans-serif;
            color: $orange;
            margin-top: 0;
            margin-bottom: 0;

            @include md {
                font-size: 32px;
                line-height: 42px;
            }

            @include xs {
                font-size: 24px;
                line-height: 34px;
            }

            @media (min-width: 1200px) and (max-width: 1366px) {
                font-size: 30px;
            }

            span {
                font-size: 32px;
                font-family: 'ElicaFavorit-Bold', sans-serif;;
                color: $orange;

                @include md {
                    font-size: 24px;
                }

                @include xs {
                    font-size: 18px;
                }
            }
        }
    }

    .numero-1 {
        background: url('../img/numeri-1.png') no-repeat 0% 50%;
        background-size: 150px;

        @include sm {
            background-size: 110px;
        }

        @include xs {
            background-position: center;
        }
    }

    .numero-2 {
        background: url('../img/numeri-2.png') no-repeat 0% 50%;
        background-size: 150px;

        @include sm {
            background-size: 110px;
        }

        @include xs {
            background-position: center;
        }
    }

    .numero-3 {
        background: url('../img/numeri-3.png') no-repeat 0% 50%;
        background-size: 90px;

        @include sm {
            background-size: 70px;
        }

        @include xs {
            background-position: center;
        }
    }

    .numero-4 {
        background: url('../img/numeri-4.png') no-repeat 0% 50%;
        background-size: 150px;

        @include sm {
            background-size: 110px;
        }

        @include xs {
            background-position: center;
        }
    }
}