article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden] {
  display: none; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  outline: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-family: 'ElicaFavorit-Bold', sans-serif; }

dfn {
  font-style: italic; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0;
  border-radius: 4px; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

@font-face {
  font-family: 'ElicaFavorit-Regular';
  src: url("/wp-content/themes/elica/dist/fonts/ElicaFavorit-Regular.woff2") format("woff2"), url("/wp-content/themes/elica/dist/fonts/ElicaFavorit-Regular.woff") format("woff"), url("/wp-content/themes/elica/dist/fonts/ElicaFavorit-Regular.otf") format("opentype");
  font-weight: normal; }

@font-face {
  font-family: 'ElicaFavorit-Medium';
  src: url("/wp-content/themes/elica/dist/fonts/ElicaFavorit-Medium.woff2") format("woff2"), url("/wp-content/themes/elica/dist/fonts/ElicaFavorit-Medium.woff") format("woff"), url("/wp-content/themes/elica/dist/fonts/ElicaFavorit-Medium.otf") format("opentype");
  font-weight: normal; }

@font-face {
  font-family: 'ElicaFavorit-Bold';
  src: url("/wp-content/themes/elica/dist/fonts/ElicaFavorit-Bold.woff2") format("woff2"), url("/wp-content/themes/elica/dist/fonts/ElicaFavorit-Bold.woff") format("woff"), url("/wp-content/themes/elica/dist/fonts/ElicaFavorit-Bold.otf") format("opentype");
  font-weight: normal; }

/*--------------------------------------------------
Base
--------------------------------------------------*/
*,
*:before,
*:after {
  box-sizing: border-box; }

html,
body {
  min-height: 100vh;
  font-family: 'ElicaFavorit-Regular', sans-serif;
  font-size: 14px; }

body {
  /* background: url('../img/bg.png') no-repeat center #fff; */
  background-size: cover; }

a {
  color: #000;
  transition: all ease-in-out .3s; }
  a:hover, a:focus {
    color: #969696;
    transition: all ease-in-out .3s; }

/*--------------------------------------------------
Utilities
--------------------------------------------------*/
.text-right {
  text-align: right; }

/*--------------------------------------------------
Grid
--------------------------------------------------*/
.wrapper {
  max-width: 1920px;
  margin: 0 auto;
  min-height: 100vh;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  overflow: hidden; }

.big-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 30px;
  position: relative; }
  @media (max-width: 767px) {
    .big-container {
      width: 100%;
      padding: 0 15px; } }

.small-container {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 30px;
  position: relative; }
  @media (max-width: 767px) {
    .small-container {
      width: 100%;
      padding: 0 15px; } }

.smaller-container {
  width: 100%;
  max-width: 864px;
  margin: 0 auto;
  padding: 0 30px; }
  @media (max-width: 991px) {
    .smaller-container {
      width: 75%; } }
  @media (max-width: 767px) {
    .smaller-container {
      width: 100%;
      padding: 0 15px; } }

.smallest-container {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 0 30px; }
  @media (max-width: 1560px) {
    .smallest-container {
      width: 75%; } }
  @media (max-width: 1199px) {
    .smallest-container {
      width: 90%; } }
  @media (max-width: 991px) {
    .smallest-container {
      width: 100%;
      padding: 0 30px; } }
  @media (max-width: 767px) {
    .smallest-container {
      width: 100%;
      padding: 0 15px; } }

.container {
  width: 100% !important;
  padding: 0 !important; }

/*--------------------------------------------------
Custom Checkbox
--------------------------------------------------*/
/* Customize the label (the container) */
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #969696; }

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee; }

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc; }

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #DE6011; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

/*--------------------------------------------------
Content Generico
--------------------------------------------------*/
#content-generico {
  padding: 100px 0; }
  @media (max-width: 767px) {
    #content-generico {
      padding: 50px 0; } }
  #content-generico .wrap-content-generico {
    padding: 100px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 767px) {
      #content-generico .wrap-content-generico {
        padding: 50px 0; } }
    #content-generico .wrap-content-generico .item {
      width: calc(50% - 40px); }
      #content-generico .wrap-content-generico .item p {
        font-size: 16px;
        line-height: 26px; }
  #content-generico .cta-404 {
    position: relative;
    margin: 40px auto;
    text-align: center; }

/*--------------------------------------------------
Cookies
--------------------------------------------------*/
#cookieBar {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 10px;
  text-align: center;
  background: #fff;
  font-size: small;
  z-index: 100; }
  @media (max-width: 991px) {
    #cookieBar {
      text-align: left; } }
  #cookieBar a {
    color: #4C4C4C;
    text-decoration: underline; }
  #cookieBar .close-cookie {
    position: relative;
    color: #DE6011;
    margin-left: 10px;
    font-size: 18px;
    top: 3px; }

/*--------------------------------------------------
Buttons
--------------------------------------------------*/
.btn {
  padding: 12px 24px;
  font-size: 14px;
  /* text-transform: uppercase; */
  text-decoration: none;
  font-family: 'ElicaFavorit-Bold', sans-serif;
  border-radius: 4px; }

.btn-orange {
  background-color: #000;
  color: #fff;
  transition: all ease-in-out .3s; }
  .btn-orange:hover, .btn-orange:focus {
    background-color: #666;
    transition: all ease-in-out .3s;
    color: #fff; }

/*--------------------------------------------------
Forms
--------------------------------------------------*/
input[type="text"], input[type="email"], select, textarea {
  background-color: #fff;
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  padding: 10px 20px;
  width: 100%; }

input[type="file"] {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #C4C4C4;
  border-radius: 4px;
  width: 100%; }

/*--------------------------------------------------
Titolo Sezioni
--------------------------------------------------*/
.tit-section h1 {
  font-size: 78px;
  font-family: 'ElicaFavorit-Bold', sans-serif;
  line-height: 43px;
  color: #000;
  text-align: center; }
  @media (max-width: 1199px) {
    .tit-section h1 {
      font-size: 68px; } }
  @media (max-width: 767px) {
    .tit-section h1 {
      font-size: 42px;
      line-height: 42px; } }
  .tit-section h1:after {
    content: '';
    display: block;
    position: relative;
    margin: 0 auto;
    width: 70px;
    height: 1px;
    background-color: #000;
    margin-top: 55px; }

.txt-section {
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 80%;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 30px; }

/*--------------------------------------------------
Mission
--------------------------------------------------*/
#mission {
  padding: 100px 0 0; }
  #mission .wrap-mission {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    #mission .wrap-mission .item-1 {
      width: 30%; }
      @media (max-width: 991px) {
        #mission .wrap-mission .item-1 {
          width: 100%; } }
    #mission .wrap-mission .item-2 {
      width: calc(70% - 40px); }
      @media (max-width: 991px) {
        #mission .wrap-mission .item-2 {
          width: 100%; } }
    #mission .wrap-mission .tit-mission {
      font-size: 42px;
      font-family: 'ElicaFavorit-Bold', sans-serif;
      line-height: 53px;
      color: #000;
      margin-top: 0; }
      @media (max-width: 1199px) {
        #mission .wrap-mission .tit-mission {
          font-size: 32px; } }
      @media (max-width: 991px) {
        #mission .wrap-mission .tit-mission {
          text-align: center; } }
      #mission .wrap-mission .tit-mission:after {
        content: '';
        display: block;
        width: 70px;
        height: 1px;
        background-color: #000;
        margin-top: 30px; }
        @media (max-width: 991px) {
          #mission .wrap-mission .tit-mission:after {
            position: relative;
            margin: 0 auto;
            margin-top: 30px; } }
    #mission .wrap-mission .abs-mission {
      font-size: 32px;
      line-height: 51px;
      margin-top: 0; }
      @media (max-width: 1199px) {
        #mission .wrap-mission .abs-mission {
          font-size: 24px;
          line-height: 36px; } }
      @media (max-width: 991px) {
        #mission .wrap-mission .abs-mission {
          font-size: 20px;
          line-height: 32px;
          text-align: center; } }
    #mission .wrap-mission .txt-mission p {
      font-size: 20px;
      line-height: 40px;
      margin-bottom: 50px; }
      @media (max-width: 1199px) {
        #mission .wrap-mission .txt-mission p {
          font-size: 18px;
          line-height: 32px; } }
      @media (max-width: 991px) {
        #mission .wrap-mission .txt-mission p {
          font-size: 16px;
          line-height: 28px;
          text-align: center; } }
  #mission .wrap-img-mission {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px 0; }
    #mission .wrap-img-mission .item {
      width: calc(50% - 40px); }
      @media (max-width: 991px) {
        #mission .wrap-img-mission .item {
          width: 100%; } }
      #mission .wrap-img-mission .item img {
        width: 100%;
        max-width: 100%; }
      #mission .wrap-img-mission .item .mission-1 {
        transform: translateX(-150px); }
        @media (max-width: 1560px) {
          #mission .wrap-img-mission .item .mission-1 {
            transform: initial; } }
      #mission .wrap-img-mission .item .mission-2 {
        transform: translateX(100px); }
        @media (max-width: 1199px) {
          #mission .wrap-img-mission .item .mission-2 {
            transform: initial; } }
      #mission .wrap-img-mission .item .mission-3 {
        transform: translateY(150px); }
        @media (max-width: 1199px) {
          #mission .wrap-img-mission .item .mission-3 {
            transform: initial; } }

/*--------------------------------------------------
Il Laboratorio
--------------------------------------------------*/
#laboratorio {
  padding: 50px 0; }
  #laboratorio .wrap-lab {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    #laboratorio .wrap-lab .item-1 {
      width: 30%; }
      @media (max-width: 991px) {
        #laboratorio .wrap-lab .item-1 {
          width: 100%; } }
    #laboratorio .wrap-lab .item-2 {
      width: calc(70% - 40px); }
      @media (max-width: 991px) {
        #laboratorio .wrap-lab .item-2 {
          width: 100%; } }
    #laboratorio .wrap-lab .tit-lab {
      font-size: 42px;
      font-family: 'ElicaFavorit-Bold', sans-serif;
      line-height: 43px;
      color: #000; }
      @media (max-width: 1199px) {
        #laboratorio .wrap-lab .tit-lab {
          font-size: 32px; } }
      @media (max-width: 991px) {
        #laboratorio .wrap-lab .tit-lab {
          text-align: center; } }
      #laboratorio .wrap-lab .tit-lab:after {
        content: '';
        display: block;
        width: 70px;
        height: 1px;
        background-color: #000;
        margin-top: 30px; }
        @media (max-width: 991px) {
          #laboratorio .wrap-lab .tit-lab:after {
            position: relative;
            margin: 0 auto;
            margin-top: 30px; } }
    #laboratorio .wrap-lab .abs-lab {
      font-size: 32px;
      line-height: 51px; }
      @media (max-width: 1199px) {
        #laboratorio .wrap-lab .abs-lab {
          font-size: 24px;
          line-height: 36px; } }
      @media (max-width: 991px) {
        #laboratorio .wrap-lab .abs-lab {
          font-size: 20px;
          line-height: 32px;
          text-align: center; } }
    #laboratorio .wrap-lab .txt-lab {
      font-size: 20px;
      line-height: 40px;
      margin-bottom: 50px; }
      @media (max-width: 1199px) {
        #laboratorio .wrap-lab .txt-lab {
          font-size: 18px;
          line-height: 32px; } }
      @media (max-width: 991px) {
        #laboratorio .wrap-lab .txt-lab {
          font-size: 16px;
          line-height: 28px;
          text-align: center; } }

/*--------------------------------------------------
Elica
--------------------------------------------------*/
#elica .wrap-elica {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background: #fff; }
  #elica .wrap-elica .border-elica {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #B3CFD6;
    border-radius: 5px;
    padding: 50px; }
    @media (max-width: 767px) {
      #elica .wrap-elica .border-elica {
        padding: 30px; } }
  #elica .wrap-elica .item {
    width: calc(70% - 40px); }
    #elica .wrap-elica .item:first-child {
      width: calc(30% - 40px); }
      @media (max-width: 991px) {
        #elica .wrap-elica .item:first-child {
          width: 100%;
          text-align: center; } }
      #elica .wrap-elica .item:first-child img {
        max-width: 200px;
        margin-bottom: 20px; }
        @media (max-width: 767px) {
          #elica .wrap-elica .item:first-child img {
            max-width: 150px; } }
    @media (max-width: 991px) {
      #elica .wrap-elica .item {
        width: 100%;
        text-align: center; } }
    #elica .wrap-elica .item img {
      width: 100%;
      max-width: 100%; }
    #elica .wrap-elica .item .tit-elica {
      font-size: 70px;
      font-family: 'ElicaFavorit-Bold', sans-serif;
      line-height: 85px;
      margin: 0; }
      @media (max-width: 1199px) {
        #elica .wrap-elica .item .tit-elica {
          font-size: 60px; } }
      @media (max-width: 991px) {
        #elica .wrap-elica .item .tit-elica {
          font-size: 50px; } }
      @media (max-width: 767px) {
        #elica .wrap-elica .item .tit-elica {
          font-size: 32px;
          line-height: 42px;
          margin-bottom: 20px; } }
    #elica .wrap-elica .item .txt-elica {
      font-size: 16px;
      line-height: 27px;
      margin-top: 0;
      margin-bottom: 0; }
      @media (max-width: 767px) {
        #elica .wrap-elica .item .txt-elica {
          font-size: 14px;
          line-height: 24px; } }

/*--------------------------------------------------
Area Test
--------------------------------------------------*/
#area-test {
  padding: 50px 0 0; }
  #area-test .wrap-area-test {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
    #area-test .wrap-area-test .item {
      width: calc(50% - 40px);
      margin: 40px 0; }
      #area-test .wrap-area-test .item img {
        width: 100%;
        max-width: 100%; }
      @media (max-width: 991px) {
        #area-test .wrap-area-test .item {
          width: 100%;
          margin: 0;
          text-align: center; } }
      #area-test .wrap-area-test .item .caption-area-test {
        width: 70%;
        transform: translateX(100px); }
        @media (max-width: 991px) {
          #area-test .wrap-area-test .item .caption-area-test {
            padding: 30px 0; } }
        @media (max-width: 767px) {
          #area-test .wrap-area-test .item .caption-area-test {
            width: 100%;
            transform: initial;
            text-align: center; } }
        #area-test .wrap-area-test .item .caption-area-test .tit-area-test {
          font-size: 32px;
          line-height: 42px;
          font-family: 'ElicaFavorit-Bold', sans-serif;
          color: #000;
          margin-top: 0; }
          @media (max-width: 1199px) {
            #area-test .wrap-area-test .item .caption-area-test .tit-area-test {
              font-size: 24px;
              line-height: 32px; } }
          #area-test .wrap-area-test .item .caption-area-test .tit-area-test:after {
            content: '';
            display: block;
            width: 70px;
            height: 1px;
            background-color: #000;
            margin-top: 20px; }
            @media (max-width: 991px) {
              #area-test .wrap-area-test .item .caption-area-test .tit-area-test:after {
                position: relative;
                margin: 0 auto;
                margin-top: 30px; } }
        #area-test .wrap-area-test .item .caption-area-test .txt-area-test {
          font-size: 16px;
          line-height: 28px; }
          @media (max-width: 1199px) {
            #area-test .wrap-area-test .item .caption-area-test .txt-area-test {
              font-size: 14px;
              line-height: 26px; } }
        #area-test .wrap-area-test .item .caption-area-test .read-more {
          font-size: 16px;
          font-family: 'ElicaFavorit-Bold', sans-serif; }
          @media (max-width: 991px) {
            #area-test .wrap-area-test .item .caption-area-test .read-more {
              font-size: 14px; } }
      #area-test .wrap-area-test .item .img-bottom {
        transform: translateY(100px); }
  #area-test .sicurezza-img {
    order: 0; }
    @media (max-width: 991px) {
      #area-test .sicurezza-img {
        order: 1; } }
  #area-test .sicurezza-txt {
    order: 1; }
    @media (max-width: 991px) {
      #area-test .sicurezza-txt {
        order: 0; } }
    @media (max-width: 991px) {
      #area-test .sicurezza-txt img {
        transform: initial !important; } }
  #area-test .prestazioni-txt {
    order: 2; }
    @media (max-width: 991px) {
      #area-test .prestazioni-txt {
        order: 2; } }
    @media (max-width: 991px) {
      #area-test .prestazioni-txt img {
        transform: initial !important; } }
  #area-test .prestazioni-img {
    order: 3; }
    @media (max-width: 991px) {
      #area-test .prestazioni-img {
        order: 3; } }
  #area-test .rumore-img {
    order: 4; }
    @media (max-width: 991px) {
      #area-test .rumore-img {
        order: 5; } }
  #area-test .rumore-txt {
    order: 5; }
    @media (max-width: 991px) {
      #area-test .rumore-txt {
        order: 4; } }
    @media (max-width: 991px) {
      #area-test .rumore-txt img {
        transform: initial !important; } }
  #area-test .elettro-txt {
    order: 6; }
    @media (max-width: 991px) {
      #area-test .elettro-txt {
        order: 6; } }
    @media (max-width: 991px) {
      #area-test .elettro-txt img {
        transform: initial !important; } }
  #area-test .elettro-img {
    order: 7; }
    @media (max-width: 991px) {
      #area-test .elettro-img {
        order: 7; } }
  #area-test .pack-img {
    order: 8; }
    @media (max-width: 991px) {
      #area-test .pack-img {
        order: 9; } }
  #area-test .pack-txt {
    order: 9; }
    @media (max-width: 991px) {
      #area-test .pack-txt {
        order: 8; } }
    @media (max-width: 991px) {
      #area-test .pack-txt img {
        transform: initial !important; } }

/*--------------------------------------------------
List Test
--------------------------------------------------*/
#list-test {
  padding: 100px 0 0; }
  @media (max-width: 767px) {
    #list-test {
      padding: 50px 0 0; } }
  #list-test .wrap-list-test {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
    #list-test .wrap-list-test .item {
      width: calc(50% - 40px);
      margin: 40px 0; }
      @media (max-width: 767px) {
        #list-test .wrap-list-test .item {
          width: 100%;
          margin: 20px 0;
          text-align: center; } }
      #list-test .wrap-list-test .item img {
        width: 100%;
        max-width: 100%; }
      #list-test .wrap-list-test .item .tit-list-test {
        font-size: 42px;
        line-height: 52px;
        font-family: 'ElicaFavorit-Bold', sans-serif;
        color: #000;
        margin-top: 0; }
        @media (max-width: 1199px) {
          #list-test .wrap-list-test .item .tit-list-test {
            font-size: 32px;
            line-height: 42px; } }
        @media (max-width: 991px) {
          #list-test .wrap-list-test .item .tit-list-test {
            font-size: 24px;
            line-height: 32px; } }
        @media (max-width: 991px) {
          #list-test .wrap-list-test .item .tit-list-test {
            margin-top: 20px; } }
        #list-test .wrap-list-test .item .tit-list-test:after {
          content: '';
          display: block;
          width: 70px;
          height: 1px;
          background-color: #000;
          margin-top: 30px; }
          @media (max-width: 767px) {
            #list-test .wrap-list-test .item .tit-list-test:after {
              position: relative;
              margin: 0 auto;
              margin-top: 30px; } }
      #list-test .wrap-list-test .item .txt-list-test {
        font-size: 20px;
        line-height: 32px; }
        @media (max-width: 1199px) {
          #list-test .wrap-list-test .item .txt-list-test {
            font-size: 16px;
            line-height: 28px; } }
        @media (max-width: 991px) {
          #list-test .wrap-list-test .item .txt-list-test {
            font-size: 14px;
            line-height: 26px; } }
      #list-test .wrap-list-test .item .read-more {
        font-size: 16px;
        font-family: 'ElicaFavorit-Bold', sans-serif; }
        @media (max-width: 991px) {
          #list-test .wrap-list-test .item .read-more {
            font-size: 14px; } }
  #list-test .sicurezza-img {
    order: 0; }
    @media (max-width: 767px) {
      #list-test .sicurezza-img {
        order: 1; } }
  #list-test .sicurezza-txt {
    order: 1; }
    @media (max-width: 767px) {
      #list-test .sicurezza-txt {
        order: 0; } }
  #list-test .prestazioni-txt {
    order: 2; }
    @media (max-width: 767px) {
      #list-test .prestazioni-txt {
        order: 2; } }
  #list-test .prestazioni-img {
    order: 3; }
    @media (max-width: 767px) {
      #list-test .prestazioni-img {
        order: 3; } }
  #list-test .rumore-img {
    order: 4; }
    @media (max-width: 767px) {
      #list-test .rumore-img {
        order: 5; } }
  #list-test .rumore-txt {
    order: 5; }
    @media (max-width: 767px) {
      #list-test .rumore-txt {
        order: 4; } }
  #list-test .elettro-txt {
    order: 6; }
    @media (max-width: 767px) {
      #list-test .elettro-txt {
        order: 6; } }
  #list-test .elettro-img {
    order: 7; }
    @media (max-width: 767px) {
      #list-test .elettro-img {
        order: 7; } }
  #list-test .pack-img {
    order: 8; }
    @media (max-width: 767px) {
      #list-test .pack-img {
        order: 9; } }
  #list-test .pack-txt {
    order: 9; }
    @media (max-width: 767px) {
      #list-test .pack-txt {
        order: 8; } }

/*--------------------------------------------------
Dettaglio Area
--------------------------------------------------*/
#list-dett-area {
  padding: 100px 0; }
  @media (max-width: 767px) {
    #list-dett-area {
      padding: 50px 0 0; } }
  #list-dett-area .wrap-list-dett-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
    #list-dett-area .wrap-list-dett-area .item {
      width: calc(50% - 40px);
      margin: 40px 0; }
      @media (max-width: 767px) {
        #list-dett-area .wrap-list-dett-area .item {
          width: 100%;
          margin: 20px 0;
          text-align: center; } }
      #list-dett-area .wrap-list-dett-area .item img {
        /*width: 100%;*/
        max-width: 100%; }
      #list-dett-area .wrap-list-dett-area .item .tit-list-dett-area {
        font-size: 42px;
        line-height: 52px;
        font-family: 'ElicaFavorit-Bold', sans-serif;
        color: #000;
        margin-top: 0; }
        @media (max-width: 1199px) {
          #list-dett-area .wrap-list-dett-area .item .tit-list-dett-area {
            font-size: 32px;
            line-height: 42px; } }
        @media (max-width: 991px) {
          #list-dett-area .wrap-list-dett-area .item .tit-list-dett-area {
            font-size: 24px;
            line-height: 32px; } }
        @media (max-width: 991px) {
          #list-dett-area .wrap-list-dett-area .item .tit-list-dett-area {
            margin-top: 20px; } }
        #list-dett-area .wrap-list-dett-area .item .tit-list-dett-area:after {
          content: '';
          display: block;
          width: 70px;
          height: 1px;
          background-color: #000;
          margin-top: 30px; }
          @media (max-width: 767px) {
            #list-dett-area .wrap-list-dett-area .item .tit-list-dett-area:after {
              position: relative;
              margin: 0 auto;
              margin-top: 30px; } }
      #list-dett-area .wrap-list-dett-area .item .txt-list-dett-area {
        font-size: 20px;
        line-height: 32px; }
        @media (max-width: 1199px) {
          #list-dett-area .wrap-list-dett-area .item .txt-list-dett-area {
            font-size: 16px;
            line-height: 28px; } }
        @media (max-width: 991px) {
          #list-dett-area .wrap-list-dett-area .item .txt-list-dett-area {
            font-size: 14px;
            line-height: 26px; } }
      #list-dett-area .wrap-list-dett-area .item .read-more {
        font-size: 16px;
        font-family: 'ElicaFavorit-Bold', sans-serif; }
        @media (max-width: 991px) {
          #list-dett-area .wrap-list-dett-area .item .read-more {
            font-size: 14px; } }

.accredia-img {
  order: 0;
  text-align: center; }
  .accredia-img img {
    max-width: 300px !important; }
    @media (max-width: 767px) {
      .accredia-img img {
        max-width: 100% !important; } }
  @media (max-width: 767px) {
    .accredia-img {
      order: 1; } }

.accredia-txt {
  order: 1; }
  @media (max-width: 767px) {
    .accredia-txt {
      order: 0; } }

.ul-txt {
  order: 2; }
  @media (max-width: 767px) {
    .ul-txt {
      order: 2; } }

.ul-img {
  order: 3;
  text-align: center; }
  .ul-img img {
    max-width: 300px !important; }
    @media (max-width: 767px) {
      .ul-img img {
        max-width: 100% !important; } }
  @media (max-width: 767px) {
    .ul-img {
      order: 3; } }

.china-img {
  order: 4;
  text-align: center; }
  .china-img img {
    max-width: 300px !important; }
    @media (max-width: 767px) {
      .china-img img {
        max-width: 100% !important; } }
  @media (max-width: 767px) {
    .china-img {
      order: 5; } }

.china-txt {
  order: 5; }
  @media (max-width: 767px) {
    .china-txt {
      order: 4; } }

/*--------------------------------------------------
Partner Tecnologico
--------------------------------------------------*/
#partner-tecnologico {
  padding: 100px 0; }
  #partner-tecnologico .wrap-partner-tecnologico {
    /* padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 3px 6px rgba(0,0,0,.16);
        background: #fff; */ }
    #partner-tecnologico .wrap-partner-tecnologico .border-partner-tecnologico {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      border: 1px solid #000;
      border-radius: 0px;
      padding: 50px;
      height: 100%; }
      @media (max-width: 767px) {
        #partner-tecnologico .wrap-partner-tecnologico .border-partner-tecnologico {
          padding: 30px; } }
    #partner-tecnologico .wrap-partner-tecnologico .item {
      width: 100%; }
      @media (max-width: 991px) {
        #partner-tecnologico .wrap-partner-tecnologico .item {
          width: 100%; } }
      #partner-tecnologico .wrap-partner-tecnologico .item img {
        max-width: 100%;
        display: block;
        margin-bottom: 20px; }
      @media (max-width: 767px) {
        #partner-tecnologico .wrap-partner-tecnologico .item .btn {
          display: block;
          text-align: center; } }
      #partner-tecnologico .wrap-partner-tecnologico .item .tit-partner-tecnologico {
        font-size: 50px;
        font-family: 'ElicaFavorit-Bold', sans-serif;
        line-height: 60px;
        margin: 0 0 20px; }
        @media (max-width: 1199px) {
          #partner-tecnologico .wrap-partner-tecnologico .item .tit-partner-tecnologico {
            font-size: 60px; } }
        @media (max-width: 991px) {
          #partner-tecnologico .wrap-partner-tecnologico .item .tit-partner-tecnologico {
            font-size: 50px; } }
        @media (max-width: 767px) {
          #partner-tecnologico .wrap-partner-tecnologico .item .tit-partner-tecnologico {
            font-size: 32px;
            line-height: 42px;
            margin-bottom: 20px; } }
      #partner-tecnologico .wrap-partner-tecnologico .item .txt-partner-tecnologico {
        font-size: 16px;
        line-height: 27px;
        margin-top: 0; }
        @media (max-width: 767px) {
          #partner-tecnologico .wrap-partner-tecnologico .item .txt-partner-tecnologico {
            font-size: 14px;
            line-height: 24px; } }
      #partner-tecnologico .wrap-partner-tecnologico .item .read-more {
        font-size: 16px;
        font-family: 'ElicaFavorit-Bold', sans-serif; }
  #partner-tecnologico .wrap-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    #partner-tecnologico .wrap-wrap .wrap-partner-tecnologico {
      width: calc(50% - 10px); }
      @media (max-width: 991px) {
        #partner-tecnologico .wrap-wrap .wrap-partner-tecnologico {
          width: 100%; } }
      #partner-tecnologico .wrap-wrap .wrap-partner-tecnologico .item .tit-partner-tecnologico {
        font-size: 30px;
        margin: 0; }

/*--------------------------------------------------
Contatti
--------------------------------------------------*/
#contatti {
  padding: 50px 0; }
  #contatti .wrap-contatti {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    #contatti .wrap-contatti .indirizzi {
      width: 30%; }
      @media (max-width: 991px) {
        #contatti .wrap-contatti .indirizzi {
          width: 100%; } }
      #contatti .wrap-contatti .indirizzi .social-contatto {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 30px; }
        #contatti .wrap-contatti .indirizzi .social-contatto li {
          display: inline-block;
          margin-right: 10px; }
          #contatti .wrap-contatti .indirizzi .social-contatto li:last-child {
            margin-right: 0; }
          #contatti .wrap-contatti .indirizzi .social-contatto li a {
            width: 50px;
            height: 50px;
            line-height: 50px;
            background-color: #B5B5B5;
            color: #fff;
            border-radius: 50px;
            display: block;
            text-align: center;
            font-size: 20px;
            transition: all ease-in-out .3s; }
            @media (max-width: 991px) {
              #contatti .wrap-contatti .indirizzi .social-contatto li a {
                width: 40px;
                height: 40px;
                line-height: 40px;
                border-radius: 40px;
                font-size: 16px; } }
            #contatti .wrap-contatti .indirizzi .social-contatto li a:hover, #contatti .wrap-contatti .indirizzi .social-contatto li a:focus {
              background-color: #000;
              transition: all ease-in-out .3s; }
    #contatti .wrap-contatti .content-contatti {
      width: calc(70% - 20px); }
      @media (max-width: 991px) {
        #contatti .wrap-contatti .content-contatti {
          width: 100%; } }
      #contatti .wrap-contatti .content-contatti .txt-contatti {
        position: relative;
        margin: 0 auto;
        text-align: center;
        width: 80%;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 30px; }
        @media (max-width: 991px) {
          #contatti .wrap-contatti .content-contatti .txt-contatti {
            font-size: 16px;
            line-height: 26px; } }
        @media (max-width: 767px) {
          #contatti .wrap-contatti .content-contatti .txt-contatti {
            width: 100%;
            font-size: 14px;
            line-height: 24px; } }
      #contatti .wrap-contatti .content-contatti #form-contatti .wrap-form-contatti {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
        #contatti .wrap-contatti .content-contatti #form-contatti .wrap-form-contatti .item {
          width: calc(50% - 40px);
          margin: 10px 0; }
          #contatti .wrap-contatti .content-contatti #form-contatti .wrap-form-contatti .item.full {
            width: 100%; }
          #contatti .wrap-contatti .content-contatti #form-contatti .wrap-form-contatti .item .allega {
            color: #000;
            font-family: 'ElicaFavorit-Bold', sans-serif;
            font-size: 16px; }
      #contatti .wrap-contatti .content-contatti #form-contatti .btn {
        position: relative;
        margin: 0 auto;
        display: block;
        width: 30%;
        text-align: center; }

/*--------------------------------------------------
Header
--------------------------------------------------*/
#header {
  position: absolute;
  background: transparent;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; }
  #header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%; }
    #header.sticky .wrap-header {
      padding: 20px 0; }
      #header.sticky .wrap-header .logo svg .st0 {
        fill: #000; }
      #header.sticky .wrap-header .menu ul li a {
        color: #000; }
      #header.sticky .wrap-header .extra .lang a {
        color: #000; }
      #header.sticky .wrap-header .extra .hamburger a {
        color: #000; }
  #header .wrap-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px; }
    @media (max-width: 1199px) {
      #header .wrap-header {
        align-items: initial; } }
    @media (max-width: 991px) {
      #header .wrap-header {
        align-items: center; } }
    #header .wrap-header .logo {
      width: 10%; }
      @media (max-width: 1199px) {
        #header .wrap-header .logo {
          width: 5%; } }
      @media (max-width: 991px) {
        #header .wrap-header .logo {
          width: 50%; } }
      #header .wrap-header .logo svg {
        max-width: 120px; }
        @media (max-width: 767px) {
          #header .wrap-header .logo svg {
            max-width: 80px; } }
    #header .wrap-header .menu {
      width: 70%;
      display: inline; }
      @media (max-width: 1199px) {
        #header .wrap-header .menu {
          width: 75%; } }
      @media (max-width: 991px) {
        #header .wrap-header .menu {
          display: none; } }
      #header .wrap-header .menu ul {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: center; }
        #header .wrap-header .menu ul li {
          display: inline-block;
          padding: 0 5px; }
          #header .wrap-header .menu ul li a {
            color: #fff;
            font-family: 'ElicaFavorit-Regular', sans-serif;
            /* text-transform: uppercase; */
            text-decoration: none;
            padding: 0 10px;
            transition: all ease-in-out .3s;
            font-size: 15px; }
            @media (max-width: 1199px) {
              #header .wrap-header .menu ul li a {
                font-size: 14px;
                padding: 0 5px; } }
            #header .wrap-header .menu ul li a:hover, #header .wrap-header .menu ul li a:focus {
              color: #c9c9c9;
              transition: all ease-in-out .3s; }
            #header .wrap-header .menu ul li a.active {
              color: #000; }
    #header .wrap-header .extra {
      width: 20%;
      text-align: right; }
      @media (max-width: 991px) {
        #header .wrap-header .extra {
          width: 50%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center; } }
      #header .wrap-header .extra .lang {
        display: inline-block;
        margin-right: 15px;
        position: relative; }
        @media (max-width: 1199px) {
          #header .wrap-header .extra .lang {
            margin-right: 0; } }
        @media (max-width: 767px) {
          #header .wrap-header .extra .lang {
            width: 80%; } }
        #header .wrap-header .extra .lang a {
          color: #fff;
          text-decoration: none;
          text-transform: uppercase;
          font-family: 'ElicaFavorit-Bold', sans-serif;
          font-size: 16px; }
          @media (max-width: 1199px) {
            #header .wrap-header .extra .lang a {
              font-size: 14px; } }
          #header .wrap-header .extra .lang a:hover, #header .wrap-header .extra .lang a:focus {
            color: #c9c9c9;
            transition: all ease-in-out .3s; }
        #header .wrap-header .extra .lang .list-lang {
          display: none; }
          #header .wrap-header .extra .lang .list-lang.open {
            display: block;
            position: absolute;
            right: -5px;
            top: 35px;
            z-index: 300;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); }
          #header .wrap-header .extra .lang .list-lang ul {
            list-style: none;
            margin: 0;
            padding: 0;
            background-color: #fff;
            text-align: left;
            padding: 10px; }
            #header .wrap-header .extra .lang .list-lang ul li {
              display: block; }
              #header .wrap-header .extra .lang .list-lang ul li a {
                color: #969696;
                font-family: 'ElicaFavorit-Bold', sans-serif;
                border-bottom: 1px solid #ddd;
                display: block;
                width: 100%; }
                #header .wrap-header .extra .lang .list-lang ul li a:hover, #header .wrap-header .extra .lang .list-lang ul li a:focus {
                  color: #c9c9c9; }
                #header .wrap-header .extra .lang .list-lang ul li a.active {
                  color: #c9c9c9; }
              #header .wrap-header .extra .lang .list-lang ul li:last-child a {
                border-bottom: 0; }
      @media (max-width: 1199px) {
        #header .wrap-header .extra .btn {
          font-size: 12px;
          padding: 10px 5px; } }
      @media (max-width: 991px) {
        #header .wrap-header .extra .btn {
          font-size: 14px;
          padding: 10px 20px; } }
      @media (max-width: 767px) {
        #header .wrap-header .extra .btn {
          display: none; } }
      #header .wrap-header .extra .hamburger {
        display: none; }
        #header .wrap-header .extra .hamburger a {
          color: #fff;
          font-size: 24px;
          transition: all ease-in-out .3s; }
          #header .wrap-header .extra .hamburger a:hover, #header .wrap-header .extra .hamburger a:focus {
            color: #fff;
            transition: all ease-in-out .3s; }
        @media (max-width: 991px) {
          #header .wrap-header .extra .hamburger {
            display: block; } }
  #header.contatti {
    position: static;
    background: #fff; }
    #header.contatti .wrap-header {
      padding: 20px 0; }
      #header.contatti .wrap-header .logo svg .st0 {
        fill: #000; }
      #header.contatti .wrap-header .menu ul li a {
        color: #000;
        transition: all ease-in-out .3s; }
        #header.contatti .wrap-header .menu ul li a:hover, #header.contatti .wrap-header .menu ul li a:focus {
          color: #000;
          transition: all ease-in-out .3s; }
        #header.contatti .wrap-header .menu ul li a.active {
          color: #000; }
      #header.contatti .wrap-header .extra .lang a {
        color: #000;
        transition: all ease-in-out .3s; }
        #header.contatti .wrap-header .extra .lang a:hover, #header.contatti .wrap-header .extra .lang a:focus {
          color: #000;
          transition: all ease-in-out .3s; }
      #header.contatti .wrap-header .extra .hamburger a {
        color: #000;
        transition: all ease-in-out .3s; }
        #header.contatti .wrap-header .extra .hamburger a:hover, #header.contatti .wrap-header .extra .hamburger a:focus {
          color: #000;
          transition: all ease-in-out .3s; }
    #header.contatti.sticky {
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      width: 100%; }
      #header.contatti.sticky .wrap-header {
        padding: 20px 0; }
        #header.contatti.sticky .wrap-header .logo svg .st0 {
          fill: #000; }
        #header.contatti.sticky .wrap-header .menu ul li a {
          color: #000; }
        #header.contatti.sticky .wrap-header .extra .lang a {
          color: #000; }
        #header.contatti.sticky .wrap-header .extra .hamburger a {
          color: #000; }

/*--------------------------------------------------
Menu Mobile
--------------------------------------------------*/
#menu-mobile {
  display: none; }
  @media (max-width: 991px) {
    #menu-mobile {
      display: none; } }
  #menu-mobile.sticky {
    position: fixed !important;
    top: 85px !important;
    left: 0;
    width: 100%; }
  #menu-mobile.open {
    display: none; }
    #menu-mobile.open ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      #menu-mobile.open ul li {
        display: block; }
        #menu-mobile.open ul li a {
          text-decoration: none;
          padding: 8px 0;
          font-size: 16px;
          display: block;
          border-bottom: 1px solid #ddd;
          color: #000;
          transition: all ease-in-out .3s; }
          #menu-mobile.open ul li a:hover, #menu-mobile.open ul li a:focus {
            color: #000;
            transition: all ease-in-out .3s; }
          #menu-mobile.open ul li a.active {
            color: #000; }
        #menu-mobile.open ul li:last-child a {
          border-bottom: 0; }
        #menu-mobile.open ul li .btn {
          display: none; }
          @media (max-width: 767px) {
            #menu-mobile.open ul li .btn {
              display: block;
              font-size: 14px;
              padding: 10px;
              color: #fff; }
              #menu-mobile.open ul li .btn:hover, #menu-mobile.open ul li .btn:focus {
                color: #fff; } }
    @media (max-width: 991px) {
      #menu-mobile.open {
        display: block;
        position: absolute;
        top: 85px;
        width: 100%;
        z-index: 200;
        background: #fff;
        padding: 20px 0; } }

/*--------------------------------------------------
Slideshow Hp
--------------------------------------------------*/
#slide-hp {
  background: #000;
  /* iPhone X */
  /* iPhone 8 */
  /* iPhone 8 Landscape */
  /* iPhone 8+ Landscape */
  /* iPhone 8+ Portrait */
  /* iPhone 5 Portrait */
  /* iPhone 5 Landscape */ }
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    #slide-hp {
      /* height: 394px; */ } }
  @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
    #slide-hp {
      /* height: 392px; */ } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    #slide-hp {
      /* height: 279px; */ } }
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    #slide-hp {
      /* height: 307px; */ } }
  @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
    #slide-hp {
      /* height: 431px; */ } }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    #slide-hp {
      /* height: 333px; */ } }
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    #slide-hp {
      /* height: 239px; */ } }
  #slide-hp .slide-hp {
    position: relative; }
    #slide-hp .slide-hp .slick-slide {
      position: relative;
      height: 100vh;
      position: relative; }
      #slide-hp .slide-hp .slick-slide img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: 50% 50%;
        will-change: transform;
        /* iPhone 8 Landscape */
        /* iPhone 8+ Landscape */ }
        #slide-hp .slide-hp .slick-slide img.desktop {
          display: block; }
          @media (max-width: 767px) {
            #slide-hp .slide-hp .slick-slide img.desktop {
              display: none; } }
        #slide-hp .slide-hp .slick-slide img.mobile {
          display: none; }
          @media (max-width: 767px) {
            #slide-hp .slide-hp .slick-slide img.mobile {
              display: block;
              min-height: 300px;
              width: auto; } }
        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
          #slide-hp .slide-hp .slick-slide img {
            width: 100%; } }
        @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
          #slide-hp .slide-hp .slick-slide img {
            width: 100%; } }
    #slide-hp .slide-hp .slick-prev {
      left: 20px;
      z-index: 100;
      height: 50px;
      width: 50px; }
      @media (max-width: 767px) {
        #slide-hp .slide-hp .slick-prev {
          height: 20px;
          width: 20px; } }
      #slide-hp .slide-hp .slick-prev:before {
        content: '\f104';
        font-family: 'Font Awesome 5 Pro';
        font-size: 23px;
        height: 50px;
        width: 50px;
        display: block;
        line-height: 47px;
        border-radius: 50px;
        border: 2px solid #fff; }
    #slide-hp .slide-hp .slick-next {
      right: 60px;
      z-index: 100;
      height: 50px;
      width: 50px; }
      @media (max-width: 767px) {
        #slide-hp .slide-hp .slick-next {
          height: 20px;
          width: 20px; } }
      #slide-hp .slide-hp .slick-next:before {
        content: '\f105';
        font-family: 'Font Awesome 5 Pro';
        font-size: 23px;
        height: 50px;
        width: 50px;
        display: block;
        line-height: 47px;
        border-radius: 50px;
        border: 2px solid #fff; }
    #slide-hp .slide-hp .slick-dots {
      bottom: 25px; }
      #slide-hp .slide-hp .slick-dots li.slick-active button {
        background-color: #fff;
        transition: all ease-in-out .3s; }
      #slide-hp .slide-hp .slick-dots li button {
        background: transparent;
        border: 2px solid #fff;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        transition: all ease-in-out .3s; }
        #slide-hp .slide-hp .slick-dots li button:hover, #slide-hp .slide-hp .slick-dots li button:focus {
          background: #fff;
          transition: all ease-in-out .3s; }
        #slide-hp .slide-hp .slick-dots li button:before {
          display: none; }
    #slide-hp .slide-hp .caption-slide-hp {
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 60%;
      height: 400px;
      color: #fff;
      z-index: 1;
      /* iPhone 5 Landscape */ }
      @media (max-width: 1199px) {
        #slide-hp .slide-hp .caption-slide-hp {
          height: 320px; } }
      @media (max-width: 991px) {
        #slide-hp .slide-hp .caption-slide-hp {
          width: 70%;
          height: 140px; } }
      @media (max-width: 767px) {
        #slide-hp .slide-hp .caption-slide-hp {
          width: 56%;
          height: 100px;
          text-align: center; } }
      @media (max-width: 375px) {
        #slide-hp .slide-hp .caption-slide-hp {
          text-align: center;
          width: 46%;
          height: 80px; } }
      @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        #slide-hp .slide-hp .caption-slide-hp {
          height: 100px;
          text-align: center; } }
      #slide-hp .slide-hp .caption-slide-hp .abs-slide-hp {
        font-size: 12px;
        font-family: 'ElicaFavorit-Regular', sans-serif;
        color: #fff !important;
        line-height: normal;
        margin-bottom: 0;
        /* background-color: #000; */
        display: inline-block;
        padding: 6px 10px;
        /* text-transform: uppercase; */
        font-weight: normal;
        /* iPhone 5 Landscape */ }
        @media (max-width: 1199px) {
          #slide-hp .slide-hp .caption-slide-hp .abs-slide-hp {
            font-size: 24px;
            line-height: 34px; } }
        @media (max-width: 991px) {
          #slide-hp .slide-hp .caption-slide-hp .abs-slide-hp {
            font-size: 16px;
            line-height: 26px;
            margin: 0; } }
        @media (max-width: 767px) {
          #slide-hp .slide-hp .caption-slide-hp .abs-slide-hp {
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 20px; } }
        @media (max-width: 375px) {
          #slide-hp .slide-hp .caption-slide-hp .abs-slide-hp {
            margin-bottom: 20px; } }
        @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
          #slide-hp .slide-hp .caption-slide-hp .abs-slide-hp {
            margin-bottom: 30px; } }
      #slide-hp .slide-hp .caption-slide-hp .tit-slide-hp {
        font-size: 42px;
        font-family: 'ElicaFavorit-Regular', sans-serif !important;
        line-height: 52px;
        margin-top: 20px;
        margin-bottom: 50px;
        font-weight: normal;
        /* iPhone 5 Landscape */ }
        @media (max-width: 1199px) {
          #slide-hp .slide-hp .caption-slide-hp .tit-slide-hp {
            font-size: 26px;
            line-height: 34px; } }
        @media (max-width: 991px) {
          #slide-hp .slide-hp .caption-slide-hp .tit-slide-hp {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 30px;
            margin-top: 10px; } }
        @media (max-width: 767px) {
          #slide-hp .slide-hp .caption-slide-hp .tit-slide-hp {
            display: none; } }
        @media (max-width: 375px) {
          #slide-hp .slide-hp .caption-slide-hp .tit-slide-hp {
            display: none; } }
        @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
          #slide-hp .slide-hp .caption-slide-hp .tit-slide-hp {
            display: none; } }
      #slide-hp .slide-hp .caption-slide-hp .btn {
        background: none;
        border: 1px solid #fff; }
        #slide-hp .slide-hp .caption-slide-hp .btn:hover {
          background-color: #e5e5e5;
          color: #000; }
        @media (max-width: 991px) {
          #slide-hp .slide-hp .caption-slide-hp .btn {
            padding: 10px;
            font-size: 12px; } }

/*--------------------------------------------------
Visual Interno
--------------------------------------------------*/
#visual-interno {
  padding: 50px 0;
  background: #000;
  background-size: cover !important; }
  #visual-interno.chi-siamo {
    background-image: url("../img/chi-siamo-visual.png");
    height: 80vh; }
  #visual-interno.test {
    background-image: url("../img/test-visual.png");
    height: 80vh; }
  #visual-interno.sicurezza {
    background-image: url("../img/sicurezza-visual.png");
    height: 80vh; }
    @media (max-width: 767px) {
      #visual-interno.sicurezza {
        height: 60vh; } }
  #visual-interno.prestazione {
    background-image: url("../img/prestazione-visual.jpg");
    height: 80vh; }
    @media (max-width: 767px) {
      #visual-interno.prestazione {
        height: 60vh; } }
  #visual-interno.rumore {
    background-image: url("../img/rumore-visual.jpg");
    height: 80vh; }
    @media (max-width: 767px) {
      #visual-interno.rumore {
        height: 60vh; } }
  #visual-interno.compatibilita {
    background-image: url("../img/compatibilita-visual.jpg");
    height: 80vh; }
    @media (max-width: 767px) {
      #visual-interno.compatibilita {
        height: 60vh; } }
  #visual-interno.packaging {
    background-image: url("../img/packaging-visual.jpg");
    height: 80vh; }
    @media (max-width: 767px) {
      #visual-interno.packaging {
        height: 60vh; } }
  #visual-interno.ricerca {
    background-image: url("../img/ricerca-visual.jpg");
    height: 80vh; }
    @media (max-width: 767px) {
      #visual-interno.ricerca {
        height: 60vh; } }
  #visual-interno.accreditamento {
    background-image: url("../img/accreditamento-visual.jpg");
    height: 80vh; }
    @media (max-width: 767px) {
      #visual-interno.accreditamento {
        height: 60vh; } }
  #visual-interno .caption {
    width: 100%;
    text-align: center;
    padding: 130px 0; }
    @media (max-width: 1199px) {
      #visual-interno .caption {
        padding: 100px 0; } }
    #visual-interno .caption .tit-visual {
      font-size: 60px;
      font-family: 'ElicaFavorit-Bold', sans-serif;
      color: #fff;
      margin: 0; }
      @media (max-width: 1199px) {
        #visual-interno .caption .tit-visual {
          font-size: 42px; } }
      @media (max-width: 767px) {
        #visual-interno .caption .tit-visual {
          font-size: 32px; } }
    #visual-interno .caption .abs-visual {
      font-size: 28px;
      font-family: 'ElicaFavorit-Bold', sans-serif;
      color: #fff;
      margin-bottom: 0;
      margin-top: 30px; }
      @media (max-width: 1199px) {
        #visual-interno .caption .abs-visual {
          font-size: 22px;
          margin-top: 10px; } }
      @media (max-width: 767px) {
        #visual-interno .caption .abs-visual {
          font-size: 18px; } }
  #visual-interno .go-down {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    border: 2px solid #fff;
    background: transparent;
    position: relative;
    margin: 0 auto;
    display: block;
    color: #fff;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    opacity: .5; }
    #visual-interno .go-down:hover, #visual-interno .go-down:focus {
      opacity: 1; }

/*--------------------------------------------------
Numeri Hp
--------------------------------------------------*/
#numeri-hp {
  padding: 50px 0 0; }
  #numeri-hp .tit-numeri-hp {
    font-size: 42px;
    font-family: 'ElicaFavorit-Bold', sans-serif;
    line-height: 50px;
    margin-top: 0; }
    @media (max-width: 991px) {
      #numeri-hp .tit-numeri-hp {
        text-align: center; } }
    @media (max-width: 767px) {
      #numeri-hp .tit-numeri-hp {
        font-size: 32px;
        line-height: 42px; } }
    #numeri-hp .tit-numeri-hp:after {
      content: '';
      position: relative;
      display: block;
      width: 77px;
      height: 1px;
      margin-top: 30px;
      background-color: #000; }
      @media (max-width: 991px) {
        #numeri-hp .tit-numeri-hp:after {
          margin: 0 auto;
          margin-top: 30px; } }
  #numeri-hp .txt-numeri-hp {
    position: relative;
    margin: 0 auto;
    text-align: center;
    width: 80%;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 30px; }
    @media (max-width: 991px) {
      #numeri-hp .txt-numeri-hp {
        font-size: 16px;
        line-height: 26px; } }
    @media (max-width: 767px) {
      #numeri-hp .txt-numeri-hp {
        width: 100%;
        font-size: 14px;
        line-height: 24px; } }

.wrap-dett-numeri {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px; }
  .wrap-dett-numeri .numero {
    width: calc(25% - 20px);
    padding: 40px 0px; }
    @media (max-width: 1199px) {
      .wrap-dett-numeri .numero {
        width: calc(25% - 20px);
        text-align: center; } }
    @media (max-width: 991px) {
      .wrap-dett-numeri .numero {
        width: calc(50% - 20px);
        margin-bottom: 50px; } }
    @media (max-width: 767px) {
      .wrap-dett-numeri .numero {
        width: 100%; } }
    .wrap-dett-numeri .numero span {
      font-size: 14px;
      color: #676767;
      font-family: 'ElicaFavorit-Bold', sans-serif; }
    .wrap-dett-numeri .numero h4 {
      font-size: 50px;
      font-family: 'ElicaFavorit-Bold', sans-serif;
      color: #000;
      margin-top: 0;
      margin-bottom: 0; }
      @media (max-width: 1199px) {
        .wrap-dett-numeri .numero h4 {
          font-size: 32px;
          line-height: 42px; } }
      @media (max-width: 767px) {
        .wrap-dett-numeri .numero h4 {
          font-size: 24px;
          line-height: 34px; } }
      @media (min-width: 1200px) and (max-width: 1366px) {
        .wrap-dett-numeri .numero h4 {
          font-size: 30px; } }
      .wrap-dett-numeri .numero h4 span {
        font-size: 32px;
        font-family: 'ElicaFavorit-Bold', sans-serif;
        color: #000; }
        @media (max-width: 1199px) {
          .wrap-dett-numeri .numero h4 span {
            font-size: 24px; } }
        @media (max-width: 767px) {
          .wrap-dett-numeri .numero h4 span {
            font-size: 18px; } }
  .wrap-dett-numeri .numero-1 {
    background: url("../img/numeri-1.png") no-repeat 0% 50%;
    background-size: 150px; }
    @media (max-width: 991px) {
      .wrap-dett-numeri .numero-1 {
        background-size: 110px; } }
    @media (max-width: 767px) {
      .wrap-dett-numeri .numero-1 {
        background-position: center; } }
  .wrap-dett-numeri .numero-2 {
    background: url("../img/numeri-2.png") no-repeat 0% 50%;
    background-size: 150px; }
    @media (max-width: 991px) {
      .wrap-dett-numeri .numero-2 {
        background-size: 110px; } }
    @media (max-width: 767px) {
      .wrap-dett-numeri .numero-2 {
        background-position: center; } }
  .wrap-dett-numeri .numero-3 {
    background: url("../img/numeri-3.png") no-repeat 0% 50%;
    background-size: 90px; }
    @media (max-width: 991px) {
      .wrap-dett-numeri .numero-3 {
        background-size: 70px; } }
    @media (max-width: 767px) {
      .wrap-dett-numeri .numero-3 {
        background-position: center; } }
  .wrap-dett-numeri .numero-4 {
    background: url("../img/numeri-4.png") no-repeat 0% 50%;
    background-size: 150px; }
    @media (max-width: 991px) {
      .wrap-dett-numeri .numero-4 {
        background-size: 110px; } }
    @media (max-width: 767px) {
      .wrap-dett-numeri .numero-4 {
        background-position: center; } }

/*--------------------------------------------------
Area Test Hp
--------------------------------------------------*/
#area-test-hp {
  padding: 50px 0 0; }
  #area-test-hp .wrap-area-test-hp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
    #area-test-hp .wrap-area-test-hp .img-1 {
      order: 0; }
      @media (max-width: 991px) {
        #area-test-hp .wrap-area-test-hp .img-1 {
          order: 0; } }
    #area-test-hp .wrap-area-test-hp .txt-1 {
      order: 1; }
      @media (max-width: 991px) {
        #area-test-hp .wrap-area-test-hp .txt-1 {
          order: 1; } }
    #area-test-hp .wrap-area-test-hp .txt-2 {
      order: 2; }
      @media (max-width: 991px) {
        #area-test-hp .wrap-area-test-hp .txt-2 {
          order: 3; } }
    #area-test-hp .wrap-area-test-hp .img-2 {
      order: 3; }
      @media (max-width: 991px) {
        #area-test-hp .wrap-area-test-hp .img-2 {
          order: 2;
          margin-top: 40px !important; } }
    #area-test-hp .wrap-area-test-hp .item {
      width: calc(50% - 40px);
      margin: 40px 0; }
      @media (max-width: 991px) {
        #area-test-hp .wrap-area-test-hp .item {
          width: 100%;
          margin: 20px 0;
          text-align: center; } }
      #area-test-hp .wrap-area-test-hp .item img {
        width: 100%;
        max-width: 100%; }
        @media (max-width: 991px) {
          #area-test-hp .wrap-area-test-hp .item img {
            order: 0; } }
        #area-test-hp .wrap-area-test-hp .item img:last-of-type {
          order: 3; }
          @media (max-width: 991px) {
            #area-test-hp .wrap-area-test-hp .item img:last-of-type {
              order: 2; } }
      #area-test-hp .wrap-area-test-hp .item .tit-area-test-hp {
        font-size: 42px;
        line-height: 52px;
        font-family: 'ElicaFavorit-Medium', sans-serif;
        color: #000;
        margin-top: 0;
        font-weight: normal; }
        @media (max-width: 1199px) {
          #area-test-hp .wrap-area-test-hp .item .tit-area-test-hp {
            font-size: 32px;
            line-height: 42px; } }
        @media (max-width: 991px) {
          #area-test-hp .wrap-area-test-hp .item .tit-area-test-hp {
            font-size: 24px;
            line-height: 32px; } }
        #area-test-hp .wrap-area-test-hp .item .tit-area-test-hp:after {
          content: '';
          display: block;
          width: 70px;
          height: 1px;
          background-color: #000;
          margin-top: 30px; }
          @media (max-width: 991px) {
            #area-test-hp .wrap-area-test-hp .item .tit-area-test-hp:after {
              position: relative;
              margin: 0 auto;
              margin-top: 30px; } }
      #area-test-hp .wrap-area-test-hp .item .txt-area-test-hp {
        font-size: 20px;
        line-height: 32px; }
        @media (max-width: 1199px) {
          #area-test-hp .wrap-area-test-hp .item .txt-area-test-hp {
            font-size: 16px;
            line-height: 28px; } }
        @media (max-width: 991px) {
          #area-test-hp .wrap-area-test-hp .item .txt-area-test-hp {
            font-size: 14px;
            line-height: 26px; } }
      #area-test-hp .wrap-area-test-hp .item .read-more {
        font-size: 16px;
        font-family: 'ElicaFavorit-Bold', sans-serif; }
        @media (max-width: 991px) {
          #area-test-hp .wrap-area-test-hp .item .read-more {
            font-size: 14px; } }

/*--------------------------------------------------
Ricerca e Certificazioni Hp
--------------------------------------------------*/
#ricerca-cert-hp {
  padding: 50px 0 0; }
  @media (max-width: 767px) {
    #ricerca-cert-hp {
      padding: 0;
      margin-bottom: 50px; } }
  #ricerca-cert-hp .wrap-ricerca-cert-hp .item img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 50px; }
    @media (max-width: 767px) {
      #ricerca-cert-hp .wrap-ricerca-cert-hp .item img {
        margin-bottom: 0; } }
  #ricerca-cert-hp .wrap-ricerca-cert-hp .item .txt-ricerca-cert-hp {
    position: relative;
    margin: 0 auto;
    text-align: center;
    width: 80%;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 30px; }
    @media (max-width: 991px) {
      #ricerca-cert-hp .wrap-ricerca-cert-hp .item .txt-ricerca-cert-hp {
        font-size: 16px;
        line-height: 26px; } }
    @media (max-width: 767px) {
      #ricerca-cert-hp .wrap-ricerca-cert-hp .item .txt-ricerca-cert-hp {
        width: 100%;
        font-size: 14px;
        line-height: 24px; } }
  #ricerca-cert-hp .wrap-ricerca-cert-hp .item .read-more {
    text-align: center;
    position: relative;
    margin: 0 auto;
    display: block;
    font-size: 16px;
    font-family: 'ElicaFavorit-Bold', sans-serif; }

/*--------------------------------------------------
Certificazioni List
--------------------------------------------------*/
#list-cert {
  padding: 100px 0; }
  #list-cert .wrap-list-cert {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 80px; }
    #list-cert .wrap-list-cert .item {
      width: calc(50% - 40px);
      margin: 30px 0; }
      @media (max-width: 767px) {
        #list-cert .wrap-list-cert .item {
          width: 100%; } }
      #list-cert .wrap-list-cert .item img {
        height: 50px; }
      #list-cert .wrap-list-cert .item .caption-list-cert .tit-list-cert {
        font-size: 24px; }
      #list-cert .wrap-list-cert .item .caption-list-cert .txt-list-cert {
        font-size: 16px;
        line-height: 28px; }
      #list-cert .wrap-list-cert .item .caption-list-cert .read-more {
        font-size: 16px;
        font-family: 'ElicaFavorit-Bold', sans-serif;
        margin-top: 20px;
        display: block; }

/*--------------------------------------------------
Content Cert
--------------------------------------------------*/
#content-cert {
  padding: 100px 0; }
  @media (max-width: 767px) {
    #content-cert {
      padding: 50px 0; } }
  #content-cert .wrap-content-cert {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    #content-cert .wrap-content-cert .item {
      width: calc(50% - 40px); }
      @media (max-width: 767px) {
        #content-cert .wrap-content-cert .item {
          width: 100%; } }
      #content-cert .wrap-content-cert .item p {
        font-size: 16px;
        line-height: 28px;
        position: relative;
        margin: 80px auto;
        max-width: 80%; }
        @media (max-width: 767px) {
          #content-cert .wrap-content-cert .item p {
            margin: 40px auto; } }
      #content-cert .wrap-content-cert .item img {
        max-width: 100%; }

/*--------------------------------------------------
Proponi Progetto
--------------------------------------------------*/
#proponi-progetto {
  padding: 100px 0 100px; }
  #proponi-progetto .wrap-proponi-progetto {
    /* padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 3px 6px rgba(0,0,0,.16);
        background: #fff; */ }
    #proponi-progetto .wrap-proponi-progetto .border-proponi-progetto {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      border: 1px solid #000;
      border-radius: 0px;
      padding: 50px; }
      @media (max-width: 767px) {
        #proponi-progetto .wrap-proponi-progetto .border-proponi-progetto {
          padding: 30px; } }
    #proponi-progetto .wrap-proponi-progetto .item {
      width: calc(50% - 40px); }
      @media (max-width: 991px) {
        #proponi-progetto .wrap-proponi-progetto .item {
          width: 100%;
          text-align: center; } }
      @media (max-width: 767px) {
        #proponi-progetto .wrap-proponi-progetto .item .btn {
          display: block;
          text-align: center; } }
      #proponi-progetto .wrap-proponi-progetto .item .tit-proponi-progetto {
        font-size: 50px;
        font-family: 'ElicaFavorit-Bold', sans-serif;
        line-height: 60px;
        margin: 0; }
        @media (max-width: 1199px) {
          #proponi-progetto .wrap-proponi-progetto .item .tit-proponi-progetto {
            font-size: 60px; } }
        @media (max-width: 991px) {
          #proponi-progetto .wrap-proponi-progetto .item .tit-proponi-progetto {
            font-size: 50px;
            margin-bottom: 30px;
            text-align: center; } }
        @media (max-width: 767px) {
          #proponi-progetto .wrap-proponi-progetto .item .tit-proponi-progetto {
            font-size: 28px;
            line-height: 38px; } }
      #proponi-progetto .wrap-proponi-progetto .item .txt-proponi-progetto {
        font-size: 16px;
        line-height: 27px;
        margin-top: 0;
        margin-bottom: 40px; }
        @media (max-width: 767px) {
          #proponi-progetto .wrap-proponi-progetto .item .txt-proponi-progetto {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 30px; } }

/*--------------------------------------------------
News
--------------------------------------------------*/
#news-hp {
  padding: 0 0 50px; }
  #news-hp .wrap-news-hp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0; }
    #news-hp .wrap-news-hp .img-news {
      width: 50%; }
      #news-hp .wrap-news-hp .img-news img {
        width: 100%;
        max-width: 100%; }
      @media (max-width: 767px) {
        #news-hp .wrap-news-hp .img-news {
          width: 100%;
          margin-bottom: 30px; } }
    #news-hp .wrap-news-hp .txt-news {
      width: calc(50% - 60px); }
      @media (max-width: 767px) {
        #news-hp .wrap-news-hp .txt-news {
          width: 100%; } }
      #news-hp .wrap-news-hp .txt-news .tit-news {
        font-size: 30px;
        font-family: 'ElicaFavorit-Bold', sans-serif;
        margin-top: 0; }
        @media (max-width: 991px) {
          #news-hp .wrap-news-hp .txt-news .tit-news {
            font-size: 24px; } }
      #news-hp .wrap-news-hp .txt-news .intro-news {
        font-size: 16px;
        line-height: 28px; }
        @media (max-width: 991px) {
          #news-hp .wrap-news-hp .txt-news .intro-news {
            font-size: 14px;
            line-height: 26px; } }

/*--------------------------------------------------
Partners
--------------------------------------------------*/
#partners {
  padding: 50px 0 0; }
  #partners .wrap-partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
    #partners .wrap-partners .item {
      width: calc(25% - 40px);
      text-align: center; }
      @media (max-width: 991px) {
        #partners .wrap-partners .item {
          width: calc(50% - 40px); } }
      @media (max-width: 767px) {
        #partners .wrap-partners .item {
          width: 100%; } }
      #partners .wrap-partners .item img {
        height: 40px;
        max-width: 100%; }
        @media (max-width: 991px) {
          #partners .wrap-partners .item img {
            margin: 20px 0; } }

/*--------------------------------------------------
Big Image
--------------------------------------------------*/
#big-image {
  height: 500px;
  background-size: cover;
  background-position: center;
  background-color: #000; }
  @media (max-width: 767px) {
    #big-image {
      height: 300px; } }
  #big-image.certificazioni {
    background-image: url("../img/ricerca-2.jpg"); }

/*--------------------------------------------------
Mappa
--------------------------------------------------*/
#mappa iframe {
  margin-bottom: -6px; }

/*--------------------------------------------------
Footer
--------------------------------------------------*/
#footer {
  background-color: #000;
  padding: 50px 0;
  color: #fff; }
  #footer .wrap-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    #footer .wrap-footer .item {
      width: calc(20% - 30px); }
      @media (max-width: 1199px) {
        #footer .wrap-footer .item {
          width: calc(23% - 30px); } }
      @media (max-width: 991px) {
        #footer .wrap-footer .item {
          width: calc(33.33333% - 30px); } }
      @media (max-width: 767px) {
        #footer .wrap-footer .item {
          width: 100%;
          margin-bottom: 20px; } }
      #footer .wrap-footer .item.info-footer {
        width: 40%; }
        @media (max-width: 1199px) {
          #footer .wrap-footer .item.info-footer {
            width: 30%; } }
        @media (max-width: 991px) {
          #footer .wrap-footer .item.info-footer {
            width: 100%;
            margin-bottom: 20px; } }
        #footer .wrap-footer .item.info-footer img {
          width: 100px; }
        #footer .wrap-footer .item.info-footer svg {
          max-width: 100px; }
      #footer .wrap-footer .item .tit-menu {
        font-size: 18px;
        font-family: 'ElicaFavorit-Bold', sans-serif;
        margin-top: 17px; }
        @media (max-width: 991px) {
          #footer .wrap-footer .item .tit-menu {
            margin-bottom: 10px; } }
      #footer .wrap-footer .item .footer-menu {
        list-style: none;
        margin: 0;
        padding: 0; }
        #footer .wrap-footer .item .footer-menu li {
          display: block;
          padding: 10px 0; }
          @media (max-width: 991px) {
            #footer .wrap-footer .item .footer-menu li {
              padding: 5px 0; } }
          #footer .wrap-footer .item .footer-menu li a {
            color: #fff;
            font-size: 14px;
            text-decoration: none; }
            @media (max-width: 991px) {
              #footer .wrap-footer .item .footer-menu li a {
                font-size: 14px; } }
      #footer .wrap-footer .item .social-profiles {
        list-style: none;
        margin: 0;
        padding: 0;
        margin: 40px 0; }
        @media (max-width: 991px) {
          #footer .wrap-footer .item .social-profiles {
            margin: 20px 0; } }
        #footer .wrap-footer .item .social-profiles li {
          display: inline-block;
          margin-right: 10px; }
          #footer .wrap-footer .item .social-profiles li:last-child {
            margin-right: 0; }
          #footer .wrap-footer .item .social-profiles li a {
            width: 40px;
            height: 40px;
            line-height: 40px;
            background-color: #676767;
            color: #fff;
            border-radius: 40px;
            display: block;
            text-align: center;
            font-size: 15px;
            transition: all ease-in-out .3s; }
            @media (max-width: 991px) {
              #footer .wrap-footer .item .social-profiles li a {
                width: 40px;
                height: 40px;
                line-height: 40px;
                border-radius: 40px;
                font-size: 16px; } }
            #footer .wrap-footer .item .social-profiles li a:hover, #footer .wrap-footer .item .social-profiles li a:focus {
              background-color: #373737;
              transition: all ease-in-out .3s; }
      #footer .wrap-footer .item #nl-widget input {
        margin-bottom: 10px; }
  #footer .wrap-copy-footer {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #707070;
    padding-top: 30px;
    margin-top: 30px; }
    #footer .wrap-copy-footer .item {
      width: 50%; }
      @media (max-width: 767px) {
        #footer .wrap-copy-footer .item {
          width: 100%;
          margin-bottom: 10px; } }
      #footer .wrap-copy-footer .item p {
        margin-bottom: 0; }
      #footer .wrap-copy-footer .item a {
        color: #fff;
        text-decoration: none; }
      #footer .wrap-copy-footer .item .copy-menu {
        list-style: none;
        margin: 0;
        padding: 0; }
        #footer .wrap-copy-footer .item .copy-menu li {
          display: inline-block;
          border-right: 1px solid #000;
          padding-right: 10px;
          margin-right: 10px; }
          #footer .wrap-copy-footer .item .copy-menu li:last-child {
            border-right: 0;
            padding-right: 0;
            margin-right: 0; }
          #footer .wrap-copy-footer .item .copy-menu li a {
            color: #fff;
            text-decoration: none; }
      #footer .wrap-copy-footer .item .logo-ws {
        margin-left: 5px;
        position: relative;
        top: 3px; }
      #footer .wrap-copy-footer .item:last-child {
        text-align: right; }
        @media (max-width: 767px) {
          #footer .wrap-copy-footer .item:last-child {
            text-align: left; } }

.with_frm_style .frm_checkbox input[type="checkbox"] {
  margin-bottom: 8px; }

#frm_form_15_container input[type='text'], #frm_form_15_container input[type='email'], #frm_form_15_container textarea, #frm_form_15_container input[type='tel'], #frm_form_20_container input[type='text'], #frm_form_20_container input[type='email'], #frm_form_20_container textarea, #frm_form_20_container input[type='tel'] {
  padding: 0 20px !important;
  min-height: 44px; }

@media (max-width: 767px) {
  #slide-hp .slide-hp .caption-slide-hp .abs-slide-hp {
    font-size: 22px; }
  #menu-mobile.open ul li .btn {
    display: inline-block;
    margin: 10px 0px; }
  #visual-interno {
    background-position: center !important; }
  #slide-hp .slide-hp .slick-next {
    width: 50px;
    height: 50px;
    top: 55%;
    right: 10px; }
  #slide-hp .slide-hp .slick-prev {
    width: 50px;
    height: 50px;
    top: 55%;
    left: 10px; } }
