/*--------------------------------------------------
Dettaglio Area
--------------------------------------------------*/
#list-dett-area {
    padding: 100px 0;

    @include xs {
        padding: 50px 0 0;
    }

    .wrap-list-dett-area {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .item {
            width: calc(50% - 40px);
            margin: 40px 0;

            @include xs {
                width: 100%;
                margin: 20px 0;
                text-align: center;
            }

            img {
                /*width: 100%;*/
                max-width: 100%;
            }

            .tit-list-dett-area {
                font-size: 42px;
                line-height: 52px;
                font-family: 'ElicaFavorit-Bold', sans-serif;
                color: #000;
                margin-top: 0;

                @include md {
                    font-size: 32px;
                    line-height: 42px;
                }

                @include sm {
                    font-size: 24px;
                    line-height: 32px;
                }

                @include sm {
                    margin-top: 20px;
                }

                &:after {
                    content: '';
                    display: block;
                    width: 70px;
                    height: 1px;
                    background-color: $orange;
                    margin-top: 30px;

                    @include xs {
                        position: relative;
                        margin: 0 auto;
                        margin-top: 30px;
                    }
                }
            }

            .txt-list-dett-area {
                font-size: 20px;
                line-height: 32px;

                @include md {
                    font-size: 16px;
                    line-height: 28px;
                }

                @include sm {
                    font-size: 14px;
                    line-height: 26px;
                }
            }

            .read-more {
                font-size: 16px;
                font-family: 'ElicaFavorit-Bold', sans-serif;

                @include sm {
                    font-size: 14px;
                }
            }
        }
    }
}


.accredia-img {
    order: 0;
    text-align: center;

    img {
        max-width: 300px !important;

        @include xs {
            max-width: 100% !important;
        }
    }

    @include xs {
        order: 1;
    }
}

.accredia-txt {
    order: 1;

    @include xs {
        order: 0;
    }
}

.ul-txt {
    order: 2;

    @include xs {
        order: 2;
    }
}

.ul-img {
    order: 3;
    text-align: center;

    img {
        max-width: 300px !important;

        @include xs {
            max-width: 100% !important;
        }
    }

    @include xs {
        order: 3;
    }
}

.china-img {
    order: 4;
    text-align: center;
    
    img {
        max-width: 300px !important;

        @include xs {
            max-width: 100% !important;
        }
    }

    @include xs {
        order: 5;
    }
}

.china-txt {
    order: 5;

    @include xs {
        order: 4;
    }
}

/*--------------------------------------------------
Partner Tecnologico
--------------------------------------------------*/
#partner-tecnologico {
    padding: 100px 0;

    .wrap-partner-tecnologico {
        /* padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 3px 6px rgba(0,0,0,.16);
        background: #fff; */

        .border-partner-tecnologico {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            border: 1px solid $orange;
            border-radius: 0px;
            padding: 50px;
            height: 100%;

            @include xs {
                padding: 30px;
            }
        }

        .item {
            width: 100%;

            @include sm {
                width: 100%;
            }

            img {
                max-width: 100%;
                display: block;
                margin-bottom: 20px;
            }

            .btn {
                
                @include xs {
                    display: block;
                    text-align: center;
                }
            }

            .tit-partner-tecnologico {
                font-size: 50px;
                font-family: 'ElicaFavorit-Bold', sans-serif;
                line-height: 60px;
                margin: 0 0 20px;

                @include md {
                    font-size: 60px;
                }

                @include sm {
                    font-size: 50px;
                }

                @include xs {
                    font-size: 32px;
                    line-height: 42px;
                    margin-bottom: 20px;
                }
            }

            .txt-partner-tecnologico {
                font-size: 16px;
                line-height: 27px;
                margin-top: 0;

                @include xs {
                    font-size: 14px;
                    line-height: 24px;
                }
            }

            .read-more {
                font-size: 16px;
                font-family: 'ElicaFavorit-Bold', sans-serif;
            }
        }
    }

    .wrap-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .wrap-partner-tecnologico {
            width: calc(50% - 10px);

            @include sm {
                width: 100%;
            }

            .item {

                .tit-partner-tecnologico {
                    font-size: 30px;
                    margin: 0;
                }
            }
        }
    }
}