/*--------------------------------------------------
Footer
--------------------------------------------------*/
#footer {
    background-color: #000;
    padding: 50px 0;
    color: #fff;

    .wrap-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
            width: calc(20% - 30px);

            @include md {
                width: calc(23% - 30px);
            }

            @include sm {
                width: calc(33.33333% - 30px);
            }

            @include xs {
                width: 100%;
                margin-bottom: 20px;
            }

            &.info-footer {
                width: 40%;

                @include md {
                    width: 30%;
                }

                @include sm {
                    width: 100%;
                    margin-bottom: 20px;
                }

                img {
                    width: 100px;
                }

                svg {
                    max-width: 100px;
                }
            }

            .tit-menu {
                font-size: 18px;
                font-family: 'ElicaFavorit-Bold', sans-serif;
                margin-top: 17px;

                @include sm {
                    margin-bottom: 10px;
                }
            }

            .footer-menu {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    display: block;
                    padding: 10px 0;

                    @include sm {
                        padding: 5px 0;
                    }

                    a {
                        color: #fff;
                        font-size: 14px;
                        text-decoration: none;

                        @include sm {
                            font-size: 14px;
                        }
                    }
                }
            }

            .social-profiles {
                list-style: none;
                margin: 0; padding: 0;
                margin: 40px 0;

                @include sm {
                    margin: 20px 0;
                }

                li {
                    display: inline-block;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        background-color: #676767;
                        color: #fff;
                        border-radius: 40px;
                        display: block;
                        text-align: center;
                        font-size: 15px;
                        transition: all ease-in-out .3s;

                        @include sm {
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            border-radius: 40px;
                            font-size: 16px;
                        }

                        &:hover, &:focus {
                            background-color: #373737;
                            transition: all ease-in-out .3s;
                        }
                    }
                }
            }

            #nl-widget {

                input {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .wrap-copy-footer {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #707070;
        padding-top: 30px;
        margin-top: 30px;

        .item {
            width: 50%;

            @include xs {
                width: 100%;
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 0;
            }

            a {
                color: #fff;
                text-decoration: none;
            }

            .copy-menu {
                list-style: none;
                margin: 0;
                padding:0;

                li {
                    display: inline-block;
                    border-right: 1px solid #000;
                    padding-right: 10px;
                    margin-right: 10px;

                    &:last-child {
                        border-right: 0;
                        padding-right: 0;
                        margin-right: 0;
                    }

                    a {
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }

            .logo-ws {
                margin-left: 5px;
                position: relative;
                top: 3px;
            }

            &:last-child {
                text-align: right;

                @include xs {
                    text-align: left;
                }
            }
        }
    }
}