/*--------------------------------------------------
Big Image
--------------------------------------------------*/
#big-image {
    height: 500px;
    background-size: cover;
    background-position: center;
    background-color: #000;

    @include xs {
        height: 300px;
    }

    &.certificazioni {
        background-image: url("../img/ricerca-2.jpg");
    }
}