/*--------------------------------------------------
Forms
--------------------------------------------------*/
input[type="text"], input[type="email"], select, textarea {
    background-color: #fff;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    padding: 10px 20px;
    width: 100%;
}

input[type="file"] {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #C4C4C4;
    border-radius: 4px;
    width: 100%;
}