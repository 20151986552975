/*--------------------------------------------------
Buttons
--------------------------------------------------*/
.btn {
    padding: 12px 24px;
    font-size: 14px;
    /* text-transform: uppercase; */
    text-decoration: none;
    font-family: 'ElicaFavorit-Bold', sans-serif;
    border-radius: 4px;
}

.btn-orange {
    background-color: #000;
    color: #fff;
    transition: all ease-in-out .3s;

    &:hover, &:focus {
        background-color: #666;
        transition: all ease-in-out .3s;
        color: #fff;
    }
}