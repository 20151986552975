/*--------------------------------------------------
News
--------------------------------------------------*/
#news-hp {
    padding: 0 0 50px;

    .wrap-news-hp {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 50px 0;

        .img-news {
            width: 50%;

            img {
                width: 100%;
                max-width: 100%;
            }

            @include xs {
                width: 100%;
                margin-bottom: 30px;
            }
        }

        .txt-news {
            width: calc(50% - 60px);

            @include xs {
                width: 100%;
            }

            .tit-news {
                font-size: 30px;
                font-family: 'ElicaFavorit-Bold', sans-serif;
                margin-top: 0;

                @include sm {
                    font-size: 24px;
                }
            }

            .intro-news {
                font-size: 16px;
                line-height: 28px;

                @include sm {
                    font-size: 14px;
                    line-height: 26px;
                }
            }
        }
    }
}