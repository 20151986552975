/*--------------------------------------------------
Area Test Hp
--------------------------------------------------*/
#area-test-hp {
    padding: 50px 0 0;

    .wrap-area-test-hp {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .img-1 {
            order: 0;

            @include sm {
                order: 0;
            }
        }

        .txt-1 {
            order: 1;

            @include sm {
                order: 1;
            }
        }

        .txt-2 {
            order: 2;

            @include sm {
                order: 3;
            }
        }

        .img-2 {
            order: 3;

            @include sm {
                order: 2;
                margin-top: 40px !important;
            }
        }

        .item {
            width: calc(50% - 40px);
            margin: 40px 0;

            @include sm {
                width: 100%;
                margin: 20px 0;
                text-align: center;
            }

            img {
                width: 100%;
                max-width: 100%;

                @include sm {
                    order: 0;
                }

                &:last-of-type {
                    order: 3;

                    @include sm {
                        order: 2;
                    }
                }
            }

            .tit-area-test-hp {
                font-size: 42px;
                line-height: 52px;
                font-family: 'ElicaFavorit-Medium', sans-serif;
                color: #000;
                margin-top: 0;
                font-weight: normal;

                @include md {
                    font-size: 32px;
                    line-height: 42px;
                }

                @include sm {
                    font-size: 24px;
                    line-height: 32px;
                }

                &:after {
                    content: '';
                    display: block;
                    width: 70px;
                    height: 1px;
                    background-color: $orange;
                    margin-top: 30px;

                    @include sm {
                        position: relative;
                        margin: 0 auto;
                        margin-top: 30px;
                    }
                }
            }

            .txt-area-test-hp {
                font-size: 20px;
                line-height: 32px;

                @include md {
                    font-size: 16px;
                    line-height: 28px;
                }

                @include sm {
                    font-size: 14px;
                    line-height: 26px;
                }
            }

            .read-more {
                font-size: 16px;
                font-family: 'ElicaFavorit-Bold', sans-serif;

                @include sm {
                    font-size: 14px;
                }
            }
        }
    }
}