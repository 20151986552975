/*--------------------------------------------------
Partners
--------------------------------------------------*/
#partners {
    padding: 50px 0 0;

    .wrap-partners {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .item {
            width: calc(25% - 40px);
            text-align: center;

            @include sm {
                width: calc(50% - 40px);
            }

            @include xs {
                width: 100%;
            }

            img {
                height: 40px;
                max-width: 100%;

                @include sm {
                    margin: 20px 0;
                }
            }
        }
    }
}