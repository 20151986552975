/*--------------------------------------------------
Contatti
--------------------------------------------------*/
#contatti {
    padding: 50px 0;

    .wrap-contatti {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .indirizzi {
            width: 30%;

            @include sm {
                width: 100%;
            }

            .social-contatto {
                list-style: none;
                margin: 0;
                padding: 0;
                margin-top: 30px;

                li {
                    display: inline-block;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        background-color: #B5B5B5;
                        color: #fff;
                        border-radius: 50px;
                        display: block;
                        text-align: center;
                        font-size: 20px;
                        transition: all ease-in-out .3s;

                        @include sm {
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            border-radius: 40px;
                            font-size: 16px;
                        }

                        &:hover, &:focus {
                            background-color: $orange;
                            transition: all ease-in-out .3s;
                        }
                    }
                }
            }
        }

        .content-contatti {
            width: calc(70% - 20px);

            @include sm {
                width: 100%;
            }

            .txt-contatti {
                position: relative;
                margin: 0 auto;
                text-align: center;
                width: 80%;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 30px;

                @include sm {
                    font-size: 16px;
                    line-height: 26px;
                }

                @include xs {
                    width: 100%;
                    font-size: 14px;
                    line-height: 24px;
                }
            }

            #form-contatti {

                .wrap-form-contatti {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .item {
                        width: calc(50% - 40px);
                        margin: 10px 0;

                        &.full {
                            width: 100%;
                        }

                        .allega {
                            color: $orange;
                            font-family: 'ElicaFavorit-Bold', sans-serif;
                            font-size: 16px;
                        }
                    }
                }

                .btn {
                    position: relative;
                    margin: 0 auto;
                    display: block;
                    width: 30%;
                    text-align: center;
                }
            }
        }
    }
}