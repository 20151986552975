/*--------------------------------------------------
Proponi Progetto
--------------------------------------------------*/
#proponi-progetto {
    padding: 100px 0 100px;

    .wrap-proponi-progetto {
        /* padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 3px 6px rgba(0,0,0,.16);
        background: #fff; */

        .border-proponi-progetto {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            border: 1px solid $orange;
            border-radius: 0px;
            padding: 50px;

            @include xs {
                padding: 30px;
            }
        }

        .item {
            width: calc(50% - 40px);

            @include sm {
                width: 100%;
                text-align: center;
            }

            .btn {
                
                @include xs {
                    display: block;
                    text-align: center;
                }
            }

            .tit-proponi-progetto {
                font-size: 50px;
                font-family: 'ElicaFavorit-Bold', sans-serif;
                line-height: 60px;
                margin: 0;

                @include md {
                    font-size: 60px;
                }

                @include sm {
                    font-size: 50px;
                    margin-bottom: 30px;
                    text-align: center;
                }

                @include xs {
                    font-size: 28px;
                    line-height: 38px;
                }
            }

            .txt-proponi-progetto {
                font-size: 16px;
                line-height: 27px;
                margin-top: 0;
                margin-bottom: 40px;

                @include xs {
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 30px;
                }
            }
        }
    }
}