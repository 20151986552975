/*--------------------------------------------------
Mission
--------------------------------------------------*/
#mission {
    padding: 100px 0 0;

    .wrap-mission {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item-1 {
            width: 30%;

            @include sm {
                width: 100%;
            }
        }

        .item-2 {
            width: calc(70% - 40px);

            @include sm {
                width: 100%;
            }
        }

        .tit-mission {
            font-size: 42px;
            font-family: 'ElicaFavorit-Bold', sans-serif;
            line-height: 53px;
            color: #000;
            margin-top: 0;

            @include md {
                font-size: 32px;
            }

            @include sm {
                text-align: center;
            }

            &:after {
                content: '';
                display: block;
                width: 70px;
                height: 1px;
                background-color: #000;
                margin-top: 30px;

                @include sm {
                    position: relative;
                    margin: 0 auto;
                    margin-top: 30px;
                }
            }
        }

        .abs-mission {
            font-size: 32px;
            line-height: 51px;
            margin-top: 0;

            @include md {
                font-size: 24px;
                line-height: 36px;
            }

            @include sm {
                font-size: 20px;
                line-height: 32px;
                text-align: center;
            }
        }

        .txt-mission p {
            font-size: 20px;
            line-height: 40px;
            margin-bottom: 50px;

            @include md {
                font-size: 18px;
                line-height: 32px;
            }

            @include sm {
                font-size: 16px;
                line-height: 28px;
                text-align: center;
            }
        }
    }

    .wrap-img-mission {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 50px 0;

        .item {
            width: calc(50% - 40px);

            @include sm {
                width: 100%;
            }

            img {
                width: 100%;
                max-width: 100%;
            }

            .mission-1 {
                transform: translateX(-150px);

                @include lg {
                    transform: initial;
                }
            }

            .mission-2 {
                transform: translateX(100px);

                @include md {
                    transform: initial;
                }
            }

            .mission-3 {
                transform: translateY(150px);

                @include md {
                     transform: initial;
                }
            }
        }
    }
}

/*--------------------------------------------------
Il Laboratorio
--------------------------------------------------*/
#laboratorio {
    padding: 50px 0;

    .wrap-lab {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item-1 {
            width: 30%;

            @include sm {
                width: 100%;
            }
        }

        .item-2 {
            width: calc(70% - 40px);

             @include sm {
                 width: 100%;
             }
        }

        .tit-lab {
            font-size: 42px;
            font-family: 'ElicaFavorit-Bold', sans-serif;
            line-height: 43px;
            color: #000;

            @include md {
                font-size: 32px;
            }

            @include sm {
                text-align: center;
            }

            &:after {
                content: '';
                display: block;
                width: 70px;
                height: 1px;
                background-color: #000;
                margin-top: 30px;

                @include sm {
                    position: relative;
                    margin: 0 auto;
                    margin-top: 30px;
                }
            }
        }

        .abs-lab {
            font-size: 32px;
            line-height: 51px;

            @include md {
                font-size: 24px;
                line-height: 36px;
            }

            @include sm {
                font-size: 20px;
                line-height: 32px;
                text-align: center;
            }
        }

        .txt-lab {
            font-size: 20px;
            line-height: 40px;
            margin-bottom: 50px;

            @include md {
                font-size: 18px;
                line-height: 32px;
            }

            @include sm {
                font-size: 16px;
                line-height: 28px;
                text-align: center;
            }
        }
    }
}


/*--------------------------------------------------
Elica
--------------------------------------------------*/
#elica {

    .wrap-elica {
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 3px 6px rgba(0,0,0,.16);
        background: #fff;

        .border-elica {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
            border: 1px solid #B3CFD6;
            border-radius: 5px;
            padding: 50px;

            @include xs {
                padding: 30px;
            }
        }

        .item {
            width: calc(70% - 40px);

            &:first-child {
                width: calc(30% - 40px);

                @include sm {
                    width: 100%;
                    text-align: center;
                }

                img {
                    max-width: 200px;
                    margin-bottom: 20px;

                    @include xs {
                        max-width: 150px;
                    }
                }
            }

            @include sm {
                width: 100%;
                text-align: center;
            }

            img {
                width: 100%;
                max-width: 100%;
            }

            .tit-elica {
                font-size: 70px;
                font-family: 'ElicaFavorit-Bold', sans-serif;
                line-height: 85px;
                margin: 0;

                @include md {
                    font-size: 60px;
                }

                @include sm {
                    font-size: 50px;
                }

                @include xs {
                    font-size: 32px;
                    line-height: 42px;
                    margin-bottom: 20px;
                }
            }

            .txt-elica {
                font-size: 16px;
                line-height: 27px;
                margin-top: 0;
                margin-bottom: 0;

                @include xs {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
    }
}


/*--------------------------------------------------
Area Test
--------------------------------------------------*/
#area-test {
    padding: 50px 0 0;

    .wrap-area-test {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .item {
            width: calc(50% - 40px);
            margin: 40px 0;

            img {
                width: 100%;
                max-width: 100%;
            }

            @include sm {
                width: 100%;
                margin: 0;
                text-align: center;
            }

            .caption-area-test {
                width: 70%;
                transform: translateX(100px);

                @include sm {
                    padding: 30px 0;
                }

                @include xs {
                    width: 100%;
                    transform: initial;
                    text-align: center;
                }

                .tit-area-test {
                    font-size: 32px;
                    line-height: 42px;
                    font-family: 'ElicaFavorit-Bold', sans-serif;
                    color: #000;
                    margin-top: 0;

                    @include md {
                        font-size: 24px;
                        line-height: 32px;
                    }

                    &:after {
                        content: '';
                        display: block;
                        width: 70px;
                        height: 1px;
                        background-color: $orange;
                        margin-top: 20px;

                        @include sm {
                            position: relative;
                            margin: 0 auto;
                            margin-top: 30px;
                        }
                    }
                }

                .txt-area-test {
                    font-size: 16px;
                    line-height: 28px;

                    @include md {
                        font-size: 14px;
                        line-height: 26px;
                    }
                }

                .read-more {
                    font-size: 16px;
                    font-family: 'ElicaFavorit-Bold', sans-serif;

                    @include sm {
                        font-size: 14px;
                    }
                }
            }

            .img-bottom {
                transform: translateY(100px);
            }
        }
    }

    .sicurezza-img {
        order: 0;

        @include sm {
            order: 1;
        }
    }

    .sicurezza-txt {
        order: 1;

        @include sm {
            order: 0;
        }

        img {
            
            @include sm {
                transform: initial !important;
            }
        }
    }

    .prestazioni-txt {
        order: 2;

        @include sm {
            order: 2;
        }

        img {
            
            @include sm {
                transform: initial !important;
            }
        }
    }

    .prestazioni-img {
        order: 3;

        @include sm {
            order: 3;
        }
    }

    .rumore-img {
        order: 4;

        @include sm {
            order: 5;
        }
    }

    .rumore-txt {
        order: 5;

        @include sm {
            order: 4;
        }

        img {
            
            @include sm {
                transform: initial !important;
            }
        }
    }

    .elettro-txt {
        order: 6;

        @include sm {
            order: 6;
        }

        img {
            
            @include sm {
                transform: initial !important;
            }
        }
    }

    .elettro-img {
        order: 7;

        @include sm {
            order: 7;
        }
    }

    .pack-img {
        order: 8;

        @include sm {
            order: 9;
        }
    }

    .pack-txt {
        order: 9;

        @include sm {
            order: 8;
        }

        img {
            
            @include sm {
                transform: initial !important;
            }
        }
    }
}