@font-face {
    font-family: 'ElicaFavorit-Regular';
    src: url('/wp-content/themes/elica/dist/fonts/ElicaFavorit-Regular.woff2') format('woff2'),
         url('/wp-content/themes/elica/dist/fonts/ElicaFavorit-Regular.woff') format('woff'),
         url('/wp-content/themes/elica/dist/fonts/ElicaFavorit-Regular.otf') format('opentype');
         font-weight: normal;
  }
  
  @font-face {
    font-family: 'ElicaFavorit-Medium';
    src: url('/wp-content/themes/elica/dist/fonts/ElicaFavorit-Medium.woff2') format('woff2'),
         url('/wp-content/themes/elica/dist/fonts/ElicaFavorit-Medium.woff') format('woff'),
         url('/wp-content/themes/elica/dist/fonts/ElicaFavorit-Medium.otf') format('opentype');
         font-weight: normal;
  }
  
  @font-face {
    font-family: 'ElicaFavorit-Bold';
    src: url('/wp-content/themes/elica/dist/fonts/ElicaFavorit-Bold.woff2') format('woff2'),
         url('/wp-content/themes/elica/dist/fonts/ElicaFavorit-Bold.woff') format('woff'),
         url('/wp-content/themes/elica/dist/fonts/ElicaFavorit-Bold.otf') format('opentype');
         font-weight: normal;
  }

/*--------------------------------------------------
Base
--------------------------------------------------*/

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    min-height: 100vh;
    font-family: 'ElicaFavorit-Regular', sans-serif;
    font-size: 14px;
}

body {
    /* background: url('../img/bg.png') no-repeat center #fff; */
    background-size: cover;
}

a {
    color: #000;
    transition: all ease-in-out .3s;

    &:hover, &:focus {
        color: #969696;
        transition: all ease-in-out .3s;
    }
}

/*--------------------------------------------------
Utilities
--------------------------------------------------*/
.text-right {
    text-align: right;
}

/*--------------------------------------------------
Grid
--------------------------------------------------*/
.wrapper { 
    max-width: 1920px;    
    margin: 0 auto;
    min-height: 100vh;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    overflow: hidden;
}

.big-container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 30px;
    position: relative;
    @include xs { width: 100%; padding: 0 15px; }
}

.small-container {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 30px;
    position: relative;
    @include xs { width: 100%; padding: 0 15px; }
}

.smaller-container {
    width: 100%;
    max-width: 864px;
    margin: 0 auto;
    padding: 0 30px;
    @include sm { width: 75%; }
    @include xs { width: 100%; padding: 0 15px; }
}

.smallest-container {
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    padding: 0 30px;
    @include lg { width: 75%; }
    @include md { width: 90%; }
    @include sm { width: 100%; padding: 0 30px; }
    @include xs { width: 100%; padding: 0 15px; }
}

.container { 
    width: 100% !important;
    padding: 0 !important;
}


/*--------------------------------------------------
Custom Checkbox
--------------------------------------------------*/

/* Customize the label (the container) */
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #969696;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #DE6011;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*--------------------------------------------------
Content Generico
--------------------------------------------------*/
#content-generico {
    padding: 100px 0;

    @include xs {
        padding: 50px 0;
    }

    .wrap-content-generico {
        padding: 100px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include xs {
            padding: 50px 0;
        }

        .item {
            width: calc(50% - 40px);

            p {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }

    .cta-404 {
        position: relative;
        margin: 40px auto;
        text-align: center;
    }
}

/*--------------------------------------------------
Cookies
--------------------------------------------------*/
#cookieBar {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 10px;
    text-align: center;
    background: #fff;
    font-size: small;
    z-index: 100;

    @include sm {
        text-align: left;
    }

    a {
        color: #4C4C4C;
        text-decoration: underline;
    }

    .close-cookie {
        position: relative;
        color: #DE6011;
        margin-left: 10px;
        font-size: 18px;
        top: 3px;
    }
}

