@import "_modules/normalize";
@import "_modules/variables";
@import "_modules/base";
@import "_modules/buttons";
@import "_modules/forms";
@import "_modules/typo";

@import "_modules/chi-siamo";
@import "_modules/list-test";
@import "_modules/dettaglio-area";
@import "_modules/contatti";

@import "_modules/header";
@import "_modules/slideshow";
@import "_modules/visual";
@import "_modules/numeri";
@import "_modules/area-test";
@import "_modules/ricerca-certificazioni";
@import "_modules/proponi-progetto";
@import "_modules/news";
@import "_modules/partners";
@import "_modules/big-image";
@import "_modules/maps";
@import "_modules/footer";

.with_frm_style .frm_checkbox input[type="checkbox"] { margin-bottom: 8px; }
#frm_form_15_container input[type='text'], #frm_form_15_container input[type='email'], #frm_form_15_container textarea, #frm_form_15_container input[type='tel'], #frm_form_20_container input[type='text'], #frm_form_20_container input[type='email'], #frm_form_20_container textarea, #frm_form_20_container input[type='tel'] {
  padding: 0 20px !important;
  min-height: 44px;
}

@media (max-width: 767px) {
	#slide-hp .slide-hp .caption-slide-hp .abs-slide-hp { font-size: 22px;}
	#menu-mobile.open ul li .btn { display: inline-block; margin: 10px 0px; }
	#visual-interno { background-position: center !important; }
	#slide-hp .slide-hp .slick-next { width: 50px; height: 50px; top:55%; right: 10px; }
	#slide-hp .slide-hp .slick-prev { width: 50px; height: 50px; top: 55%; left: 10px; }
}