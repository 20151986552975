/*--------------------------------------------------
Slideshow Hp
--------------------------------------------------*/
#slide-hp {
    background: #000;

    /* iPhone X */
    @media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
        /* height: 394px; */
    }

    /* iPhone 8 */
    @media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2) {
        /* height: 392px; */
    }

    /* iPhone 8 Landscape */
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        /* height: 279px; */
    }

    /* iPhone 8+ Landscape */
    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
        /* height: 307px; */
    }

    /* iPhone 8+ Portrait */
    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
        /* height: 431px; */
    }

    /* iPhone 5 Portrait */
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        /* height: 333px; */
    }

    /* iPhone 5 Landscape */
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        /* height: 239px; */
    }

    .slide-hp {
        position: relative;

        .slick-slide {
            position: relative;
            height: 100vh;
            position: relative;

            img {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: block;
                object-fit: cover;
                object-position: 50% 50%;
                will-change: transform;

                &.desktop {
                    display: block;

                    @include xs {
                        display: none;
                    }
                }

                &.mobile {
                    display: none;

                    @include xs {
                        display: block;
                        min-height: 300px;
                        width: auto;
                    }
                }


                /* iPhone 8 Landscape */
                @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
                    width: 100%;
                }

                /* iPhone 8+ Landscape */
                @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
                    width: 100%;
                }
            }
        }


        .slick-prev {
            left: 20px;
            z-index: 100;
            height: 50px;
            width: 50px;

            @include xs {
                height: 20px;
                width: 20px;
            }

            &:before {
                content: '\f104';
                font-family: 'Font Awesome 5 Pro';
                font-size: 23px;
                height: 50px;
                width: 50px;
                display: block;
                line-height: 47px;
                border-radius: 50px;
                border: 2px solid #fff;
            }
        }

        .slick-next {
            right: 60px;
            z-index: 100;
            height: 50px;
            width: 50px;

            @include xs {
                height: 20px;
                width: 20px;
            }

            &:before {
                content: '\f105';
                font-family: 'Font Awesome 5 Pro';
                font-size: 23px;
                height: 50px;
                width: 50px;
                display: block;
                line-height: 47px;
                border-radius: 50px;
                border: 2px solid #fff;
            }
        }

        .slick-dots {
            bottom: 25px;

            li {

                &.slick-active {

                    button {
                        background-color: #fff;
                        transition: all ease-in-out .3s;
                    }
                }


                button {
                    background: transparent;
                    border: 2px solid #fff;
                    width: 10px;
                    height: 10px;
                    border-radius: 10px;
                    transition: all ease-in-out .3s;

                    &:hover,
                    &:focus {
                        background: #fff;
                        transition: all ease-in-out .3s;
                    }

                    &:before {
                        display: none;
                    }
                }
            }
        }

        .caption-slide-hp {
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 60%;
            height: 400px;
            color: #fff;
            z-index: 1;

            @include md {
                height: 320px;
            }

            @include sm {
                width: 70%;
                height: 140px;
            }

            @include xs {
                width: 56%;
                height: 100px;
                text-align: center;
            }

            @include xxs {
                text-align: center;
                width: 46%;
                height: 80px;
            }

            /* iPhone 5 Landscape */
            @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
                height: 100px;
                text-align: center;
            }

            .abs-slide-hp {
                font-size: 12px;
                font-family: 'ElicaFavorit-Regular', sans-serif;
                color: #fff !important;
                line-height: normal;
                margin-bottom: 0;
                /* background-color: #000; */
                display: inline-block;
                padding: 6px 10px;
                /* text-transform: uppercase; */
                font-weight: normal;

                @include md {
                    font-size: 24px;
                    line-height: 34px;
                }

                @include sm {
                    font-size: 16px;
                    line-height: 26px;
                    margin: 0;
                }

                @include xs {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 20px;
                }

                @include xxs {
                    margin-bottom: 20px;
                }

                /* iPhone 5 Landscape */
                @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
                    margin-bottom: 30px;
                }
            }

            .tit-slide-hp {
                font-size: 42px;
                font-family: 'ElicaFavorit-Regular', sans-serif !important;
                line-height: 52px;
                margin-top: 20px;
                margin-bottom: 50px;
                font-weight: normal;

                @include md {
                    font-size: 26px;
                    line-height: 34px;
                }

                @include sm {
                    font-size: 16px;
                    line-height: 26px;
                    margin-bottom: 30px;
                    margin-top: 10px;
                }

                @include xs {
                    display: none;
                }

                @include xxs {
                    display: none;
                }

                /* iPhone 5 Landscape */
                @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
                    display: none;
                }
            }

            .btn {
                background: none;
                border: 1px solid #fff;

                &:hover {
                    background-color: #e5e5e5;
                    color: #000;
                }

                @include sm {
                    padding: 10px;
                    font-size: 12px;
                }
            }
        }
    }
}