/*--------------------------------------------------
Ricerca e Certificazioni Hp
--------------------------------------------------*/
#ricerca-cert-hp {
    padding: 50px 0 0;

    @include xs {
        padding: 0;
        margin-bottom: 50px;
    }

    .wrap-ricerca-cert-hp {

        .item {

            img {
                width: 100%;
                max-width: 100%;
                margin-bottom: 50px;

                @include xs {
                    margin-bottom: 0;
                }
            }

            .txt-ricerca-cert-hp {
                position: relative;
                margin: 0 auto;
                text-align: center;
                width: 80%;
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 30px;

                @include sm {
                    font-size: 16px;
                    line-height: 26px;
                }

                @include xs {
                    width: 100%;
                    font-size: 14px;
                    line-height: 24px;
                }
            }

            .read-more {
                text-align: center;
                position: relative;
                margin: 0 auto;
                display: block;
                font-size: 16px;
                font-family: 'ElicaFavorit-Bold', sans-serif;
            }
        }
    }
}

/*--------------------------------------------------
Certificazioni List
--------------------------------------------------*/
#list-cert {
    padding: 100px 0;

    .wrap-list-cert {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 80px;

        .item {
            width: calc(50% - 40px);
            margin: 30px 0;
            
            @include xs {
                width: 100%;
            }

            img {
                height: 50px;
            }

            .caption-list-cert {

                .tit-list-cert {
                    font-size: 24px;
                }

                .txt-list-cert {
                    font-size: 16px;
                    line-height: 28px;
                }

                .read-more {
                    font-size: 16px;
                    font-family: 'ElicaFavorit-Bold', sans-serif;
                    margin-top: 20px;
                    display: block;
                }
            }
        }
    }
}

/*--------------------------------------------------
Content Cert
--------------------------------------------------*/
#content-cert {
    padding: 100px 0;

    @include xs {
        padding: 50px 0;
    }
    
    .wrap-content-cert {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
            width: calc(50% - 40px);
            
            @include xs {
                width: 100%;
            }

            p {
                font-size: 16px;
                line-height: 28px;
                position: relative;
                margin: 80px auto;
                max-width: 80%;

                @include xs {
                    margin: 40px auto;
                }
            }

            img {
                max-width: 100%;
            }
        }
    }
}