/*--------------------------------------------------
Header
--------------------------------------------------*/
#header {
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;

    &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        background: #fff;
        width: 100%;

        .wrap-header {
            padding: 20px 0;

            .logo {

                svg {

                    .st0 {
                        fill: #000;
                    }
                }
            }

            .menu {

                ul {
                
                    li {

                        a {
                            color: #000;
                        }
                    }
                }
            }

            .extra {

                .lang {

                    a {
                        color: #000;
                    }
                }

                .hamburger {

                    a {
                        color: #000;
                    }
                }
            }
        }
    }

    .wrap-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;

        @include md {
            align-items: initial;
        }

        @include sm {
            align-items: center;
        }

        .logo {
            width: 10%;

            @include md {
                width: 5%;
            }

            @include sm {
                width: 50%;
            }

            svg {
                max-width: 120px;

                @include xs {
                    max-width: 80px;
                }
            }
        }

        .menu {
            width: 70%;
            display: inline;

            @include md {
                width: 75%;
            }

            @include sm {
                display: none;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                text-align: center;

                li {
                    display: inline-block;
                    padding: 0 5px;

                    a {
                        color: #fff;
                        font-family: 'ElicaFavorit-Regular', sans-serif;
                        /* text-transform: uppercase; */
                        text-decoration: none;
                        padding: 0 10px;
                        transition: all ease-in-out .3s;
                        font-size: 15px;

                        @include md {
                            font-size: 14px;
                            padding: 0 5px;
                        }

                        &:hover, &:focus {
                            color: #c9c9c9;
                            transition: all ease-in-out .3s;
                        }

                        &.active {
                            color: $orange;
                        }
                    }
                }
            }
        }

        .extra {
            width: 20%;
            text-align: right;

            @include sm {
                width: 50%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
            }

            .lang {
                display: inline-block;
                margin-right: 15px;
                position: relative;

                @include md {
                    margin-right: 0;
                }

                @include xs {
                    width: 80%;
                }

                a {
                    color: #fff;
                    text-decoration: none;
                    text-transform: uppercase;
                    font-family: 'ElicaFavorit-Bold', sans-serif;
                    font-size: 16px;

                    @include md {
                        font-size: 14px;
                    }

                    &:hover, &:focus {
                        color: #c9c9c9;
                        transition: all ease-in-out .3s;
                    }
                }

                .list-lang {
                    display: none;

                    &.open {
                        display: block;
                        position: absolute;
                        right: -5px;
                        top: 35px;
                        z-index: 300;
                        box-shadow: 0 4px 10px rgba(0,0,0,.2);
                    }

                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        background-color: #fff;
                        text-align: left;
                        padding: 10px;

                        li {
                            display: block;

                            a {
                                color: #969696;
                                font-family: 'ElicaFavorit-Bold', sans-serif;
                                border-bottom: 1px solid #ddd;
                                display: block;
                                width: 100%;

                                &:hover, &:focus {
                                    color: #c9c9c9;
                                }

                                &.active {
                                    color: #c9c9c9;
                                }
                            }

                            &:last-child {

                                a {
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            .btn {

                @include md {
                    font-size: 12px;
                    padding: 10px 5px;
                }

                @include sm {
                    font-size: 14px;
                    padding: 10px 20px;
                }

                @include xs {
                    display: none;
                }
            }

            .hamburger {
                display: none;

                a {
                    color: #fff;
                    font-size: 24px;
                    transition: all ease-in-out .3s;

                    &:hover, &:focus {
                        color: #fff;
                        transition: all ease-in-out .3s;
                    }
                }

                @include sm {
                    display: block;
                }
            }
        }
    }

    &.contatti {
        position: static;
        background: #fff;

        .wrap-header {
            padding: 20px 0;

            .logo {

                svg {

                    .st0 {
                        fill: #000;
                    }
                }
            }

            .menu {

                ul {

                    li {

                        a {
                            color: #000;
                            transition: all ease-in-out .3s;

                            &:hover, &:focus {
                                color: $orange;
                                transition: all ease-in-out .3s;
                            }

                            &.active {
                                color: $orange;
                            }
                        }
                    }
                }
            }

            .extra {

                .lang {

                    a {
                        color: #000;
                        transition: all ease-in-out .3s;

                        &:hover, &:focus {
                            color: $orange;
                            transition: all ease-in-out .3s;
                        }
                    }
                }

                .hamburger {

                    a {
                        color: #000;
                        transition: all ease-in-out .3s;

                        &:hover, &:focus {
                            color: $orange;
                            transition: all ease-in-out .3s;
                        }
                    }
                }
            }
        }

        &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        background: #fff;
        width: 100%;

        .wrap-header {
            padding: 20px 0;

            .logo {

                svg {

                    .st0 {
                        fill: #000;
                    }
                }
            }

            .menu {

                ul {
                
                    li {

                        a {
                            color: #000;
                        }
                    }
                }
            }

            .extra {

                .lang {

                    a {
                        color: #000;
                    }
                }

                .hamburger {

                    a {
                        color: #000;
                    }
                }
            }
        }
    }
    }
}

/*--------------------------------------------------
Menu Mobile
--------------------------------------------------*/
#menu-mobile {
    display: none;

    @include sm {
        display: none;
    }

    &.sticky {
        position: fixed !important;
        top: 85px !important;
        left: 0;
        width: 100%;
    }

    &.open {
        display: none;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: block;

                a {
                    text-decoration: none;
                    padding: 8px 0;
                    font-size: 16px;
                    display: block;
                    border-bottom: 1px solid #ddd;
                    color: #000;
                    transition: all ease-in-out .3s;

                    &:hover, &:focus {
                        color: $orange;
                        transition: all ease-in-out .3s;
                    }

                    &.active {
                        color: $orange;
                    }
                }

                &:last-child {

                    a {
                        border-bottom: 0;
                    }
                }

                .btn {
                    display: none;

                    @include xs {
                        display: block;
                        font-size: 14px;
                        padding: 10px;
                        color: #fff;

                        &:hover, &:focus {
                            color: #fff;
                        }
                    }
                }
            }
        }

        @include sm {
            display: block;
            position: absolute;
            top:85px;
            width: 100%;
            z-index: 200;
            background: #fff;
            padding: 20px 0;
        }
    }
}